import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/fb-event'; // Cambia esto por la URL correcta de tu backend
// const baseUrl = 'http://localhost:3001/api/fb-event'; // Cambia esto por la URL correcta de tu backend

const FacebookEventService = {
  sendPurchaseEvent: async (eventData) => {
    try {
      const response = await axios.post(`${baseUrl}/send-purchase-event`, eventData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al enviar evento de compra');
    }
  },

  sendRegistrationEvent: async (eventData) => {
    try {
      const response = await axios.post(`${baseUrl}/send-registration-event`, eventData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al enviar evento de registro');
    }
  },

  sendSubscriptionEvent: async (eventData) => {
    try {
      const response = await axios.post(`${baseUrl}/send-subscription-event`, eventData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al enviar evento de suscripción');
    }
  },

  sendViewCheckoutEvent: async (eventData) => {
    try {
      const response = await axios.post(`${baseUrl}/send-view-checkout-event`, eventData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al enviar evento de visualización de checkout');
    }
  }

};

export default FacebookEventService;
