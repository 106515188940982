import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useGlobal } from '../context/GlobalContext';
import notePadService from '../services/notePadService';
import UserService from '../services/User';
import FullScreenModal from './FullScreenModal';

const MySwal = withReactContent(Swal);

const MaterialWeb = () => {
  const location = useLocation();
  const { content, materialId } = location.state || { content: '', materialId: '' };
  const { state, dispatch } = useGlobal();
  const { user } = state;

  const [title, setTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [savedNotes, setSavedNotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [activeFullScreen, setActiveFullScreen] = useState(false);
  const [modalContent, setModalContent] = useState(content);
  const [highlightMode, setHighlightMode] = useState(false);
  const [highlightColor, setHighlightColor] = useState('#FFFF00');
  const [currentMaterialId, setCurrentMaterialId] = useState(materialId);
  const [currentNoteId, setCurrentNoteId] = useState('');
  const [contentNote, setContentNote] = useState('');


  const contentEditableRef = useRef(null);

  useEffect(() => {
    const fetchSavedNotes = async () => {
      try {
        const userData = await UserService.getUserById(user.id);
        const notePadIds = userData.notePads.map((notePad) => notePad.notePadId);

        const notePads = await Promise.all(notePadIds.map((id) => notePadService.getNotePadById(id)));
        setSavedNotes(notePads);
      } catch (error) {
        console.error('Error fetching saved notes:', error);
      }
    };

    fetchSavedNotes();
  }, [user.id]);

  const handleSaveNote = async () => {
    if (title.trim() === '' || notes.trim() === '') return;

    const notePadData = {
      title,
      texto: notes,
      user: user.id,
    };

    try {
      const newNotePad = await notePadService.createNotePad(notePadData);

      await UserService.addNotePadToUser(user.id, newNotePad.id);

      setSavedNotes([...savedNotes, newNotePad]);
      setTitle('');
      setNotes('');
      MySwal.fire('¡Guardado!', 'Tu apunte ha sido guardado.', 'success');
    } catch (error) {
      console.error('Error saving note:', error);
      MySwal.fire('Error', 'Hubo un error al guardar el apunte.', 'error');
    }
  };

  const handleNoteClick = async (note) => {
    setCurrentNoteId(note.id);
    setContentNote(note.texto);
    setActiveFullScreen(true);
  };

  const handleHighlightText = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    if (!range || !range.cloneContents) return;

    const clonedSelection = range.cloneContents();
    const span = document.createElement('span');
    span.style.backgroundColor = highlightColor;

    if (range.startContainer === range.endContainer && range.startContainer.nodeType === Node.TEXT_NODE) {
      span.textContent = selection.toString();
      range.deleteContents();
      range.insertNode(span);
    } else {
      const nodes = clonedSelection.childNodes;
      nodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const textNode = document.createElement('span');
          textNode.style.backgroundColor = highlightColor;
          textNode.textContent = node.textContent;

          range.deleteContents();
          range.insertNode(textNode);
        }
      });
    }

    const newContent = contentEditableRef.current.innerHTML;
    setModalContent(newContent);
  };

  const handleSaveHighlightedMaterial = async () => {
    await UserService.saveHighlightedMaterial(user.id, currentMaterialId, modalContent);
    dispatch({
      type: 'UPDATE_HIGHLIGHTED_MATERIALS',
      payload: { materialId: currentMaterialId, content: modalContent },
    });
    setHighlightMode(false);
    MySwal.fire('¡Guardado!', 'Tus cambios resaltados han sido guardados.', 'success');
  };

  const filteredNotes = useMemo(
    () => savedNotes.filter((note) => note.title.toLowerCase().includes(searchTerm.toLowerCase())),
    [savedNotes, searchTerm]
  );

  return (
    <div className="flex flex-col min-h-screen">
      <div className="flex flex-grow">
        <div className="w-1/2 p-4 flex flex-col border-l border-gray-300 overflow-y-auto bg-white rounded-lg shadow-md" style={{
    maxHeight: 'calc(100vh - 20px)',
    marginTop: highlightMode ? '50px' : '0px',
  }}>
          <div className="p-4 bg-white shadow rounded-lg">
            <div
              ref={contentEditableRef}
              dangerouslySetInnerHTML={{ __html: modalContent }}
              className="space-y-4"
            />
          </div>
          {highlightMode && (
            <div className="absolute top-4 left-4 mb-10" style={{ maxHeight: 'calc(100vh - 20px)', marginTop: "90px", marginLeft:"20px" }}>
              
              <div className="flex space-x-2">
              <label className="block mb-2">Selecciona color:</label>
                <button
                  className="w-8 h-8 rounded-full bg-yellow-200 border-2 border-yellow-300"
                  onClick={() => setHighlightColor('#FFFF99')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-green-200 border-2 border-green-300"
                  onClick={() => setHighlightColor('#B3FFCC')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-red-200 border-2 border-red-300"
                  onClick={() => setHighlightColor('#FFCCCC')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-blue-200 border-2 border-blue-300"
                  onClick={() => setHighlightColor('#CCFFFF')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-purple-200 border-2 border-purple-300"
                  onClick={() => setHighlightColor('#E6CCFF')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-white border-2 border-gray-300"
                  onClick={() => setHighlightColor('#FFFFFF')}
                />
              </div>
            </div>
          )}
          <div className="fixed bottom-20 left-4 flex flex-col items-center space-y-2 z-50">
            {highlightMode && (
              <>
                <button
                  onClick={handleHighlightText}
                  className="bg-yellow-500 text-white p-2 rounded-full shadow-lg"
                >
                  Aplicar Resaltado
                </button>
                <button
                  onClick={handleSaveHighlightedMaterial}
                  className="bg-green-500 text-white p-2 rounded-full shadow-lg"
                >
                  Guardar Cambios
                </button>
              </>
            )}
            {!highlightMode && !activeFullScreen && (
              <button
                onClick={() => setHighlightMode(true)}
                className="bg-blue-500 text-white p-2 rounded-full shadow-lg"
              >
                Modo Resaltado
              </button>
            )}
          </div>
        </div>
        <div className="w-1/2 p-4 flex flex-col border-l border-gray-300 overflow-y-auto bg-white rounded-lg shadow-md" style={{ maxHeight: 'calc(100vh - 20px)' }}>
          <div className="p-4 mb-4 flex-grow-0">
            <h3 className="text-lg font-semibold mb-2">Cuaderno de Apuntes</h3>
            <input
              type="text"
              className="w-full p-2 border border-gray-300 rounded-lg mb-2"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              placeholder="Título del apunte"
            />
            <textarea
              className="w-full h-40 p-2 border border-gray-300 rounded-lg resize-none mb-2"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
              placeholder="Escribe tus apuntes aquí..."
            />
            <button
              onClick={handleSaveNote}
              className="w-full mt-2 p-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600"
            >
              Guardar Apunte
            </button>
          </div>
          <div className="flex-grow">
            <h3 className="text-lg font-semibold mb-2">Mis Apuntes</h3>
            <input
              className="w-full h-10 mb-4 border border-gray-300 rounded-md px-4"
              type="text"
              placeholder="Buscar Apunte..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <ul className="space-y-2">
              {filteredNotes.map((note, index) => (
                <li
                  key={index}
                  className="w-full p-4 bg-white shadow rounded-lg cursor-pointer"
                  onClick={() => handleNoteClick(note)}
                >
                  <h4 className="font-bold">{note.title}</h4>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      <FullScreenModal
        isOpen={activeFullScreen}
        onRequestClose={() => setActiveFullScreen(false)}
        content={contentNote}
        materialId={currentNoteId}
      />
      {activeFullScreen && (
        <button
          onClick={() => setActiveFullScreen(false)}
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-full shadow-lg z-50"
        >
          Cerrar
        </button>
      )}
    </div>
  );
};

export default MaterialWeb;
