import React, { useState, useEffect } from 'react';
import { useGlobal } from '../context/GlobalContext';
import CategoryPracticoService from '../services/CategoryPractico';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import { FiLock } from 'react-icons/fi';

const MySwal = withReactContent(Swal);

const CategoryPractico = () => {
  const [categoryPracticos, setCategoryPracticos] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { state } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCategoryPractico = async () => {
      if (user && user.cuerpo) {
        MySwal.fire({
          title: 'Cargando categorías de prueba...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        try {
          const categoryPracticosData = await CategoryPracticoService.getAllCategoryPracticos();
          setCategoryPracticos(categoryPracticosData);
          setTimeout(() => {
            MySwal.close();
          }, 500); // Ajuste de tiempo de cierre del modal
        } catch (error) {
          console.error('Error al obtener las categorías de prueba:', error);
          MySwal.fire('Error', 'Hubo un error al cargar las categorías de prueba. Por favor, intenta de nuevo.', 'error');
        }
      }
    };

    fetchCategoryPractico();
  }, [user]);

  const handleSubscription = () => {
    navigate('/CheckoutPage', {
      state: {
        precioRefPro: 19.99, 
        precioDctoPro: 14.99, 
        precioRefVip: 31.99, 
        precioDctoVip: 24.99, 
        precioIdVip: 'price_1PNICRBodWVXIE1Gf3zldJrN', 
        priceIdBasic: 'price_1POJrFBodWVXIE1G1NdeQ8Fk', 
        priceIdPro: 'price_1POJogBodWVXIE1GBmtLCRgZ',
      }
    });
  };

  const filteredCategoryPracticos = searchText.length === 0
    ? categoryPracticos
    : categoryPracticos.filter(categoryTest =>
        categoryTest.name.toLowerCase().includes(searchText.toLowerCase())
      );

  const handleCategoryTestClick = (categoryPractico) => {
    navigate('/practicos', { state: { categoryId: categoryPractico._id } });
  };

  return (
    <div className="flex flex-col items-center w-full min-h-screen p-6">
      {user && user.activeSus ? (
        <>
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Categorías de Prácticos</h2>
          <input
            className="w-4/5 h-10 mb-6 border border-gray-300 rounded-md px-4"
            type="text"
            placeholder="Buscar Categoría..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <ul className="w-full flex flex-col items-center">
            {filteredCategoryPracticos.map((categoryTest) => (
              <li
                key={categoryTest._id}
                className="w-11/12 max-w-md shadow-lg rounded-lg overflow-hidden p-4 mb-6 transition duration-300 ease-in-out cursor-pointer bg-white"
                onClick={() => handleCategoryTestClick(categoryTest)}
                role="button"
                tabIndex={0}
                onKeyPress={(e) => e.key === 'Enter' && handleCategoryTestClick(categoryTest)}
              >
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">{categoryTest.name}</h3>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center p-6">
          <FiLock className="text-6xl mb-6" />
          <p className="text-xl text-center mb-6">Este contenido es exclusivo para suscriptores.</p>
          <button
            onClick={handleSubscription}
            className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg text-xl md:text-sm lg:text-base"
          >
            Ver Planes
          </button>
        </div>
      )}
    </div>
  );
};

export default CategoryPractico;
