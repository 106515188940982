import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import SessionService from '../services/Session'; // Asegúrate de importar el servicio correcto
import ConversationService from '../services/Conversation';
import { useGlobal } from '../context/GlobalContext';

const OrderConfirmationConsultas = () => {
  const location = useLocation();
  const [verificationResponse, setVerificationResponse] = useState('');
  const { state } = useGlobal();
  const { user } = state;

  const verifySession = async () => {
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get('session_id');
    const amount = searchParams.get('amount');

    if (!sessionId || !amount) {
      setVerificationResponse('Información de la sesión no proporcionada');
      return;
    }

    console.log("Session ID:", sessionId);
    console.log("Amount:", amount);

    try {
      const session = await SessionService.getSessionById(sessionId);
      console.log("session:", session)
      
      if (session.used) {
        setVerificationResponse('Esta sesión ya ha sido utilizada.');
        return;
      }

      // Lógica para añadir créditos o compras
      await ConversationService.addCreditosConsulta(user.id, amount, user.subscriptionCategory);
      await SessionService.markSessionAsUsed(session._id); // Asegúrate de tener esta función en tu servicio
      await ConversationService.addPurchaseToUser(user.id, sessionId, amount);

      setVerificationResponse('Pago confirmado');
    } catch (error) {
      console.error('Error al verificar la sesión:', error);
      setVerificationResponse('Error en el pago');
    }
  };

  useEffect(() => {
    if (user && user.id) { // Asegurarse de que el usuario esté definido
      verifySession();
    }
  }, [user]);

  return (
    <section style={{ padding: '20px' }}>
      <div className="product Box-root" style={{ marginBottom: '20px' }}>
        <h3>Tu pedido se ha completado con éxito</h3>
      </div>
      {verificationResponse && <p>{verificationResponse}</p>}
    </section>
  );
};

export default OrderConfirmationConsultas;
