import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import { FaClipboard, FaDumbbell, FaBook, FaTrophy, FaBell, FaCheck, FaUser, FaCommentDots, FaTasks,  FaStar, FaHeadphones} from 'react-icons/fa';
import NewsLetter from './Newsletter';
import Correcciones from './Correcciones';
import Materials from './Materials';
import User from './User';
import MainScreen from './Corrector';
import SubscriptionCard from './CheckoutPage';
import OrderConfirmationPage from './OrderConfirmationPage';
import CancelPage from './CancelPage';
import Chat from './ChatIa';
import Practicos from './Practicos';
import ListTest from './ListTest';
import { useGlobal } from '../context/GlobalContext';
import Retos from './Retos';
import AudioScroll from './AudioComponent';
import OrderConfirmationConsultas from './OrderConsultas';
import ReferralConfirmationPage from './Referral';
import Cuestionario from './Cuestionario';
import CreateNoticia from './CreateNoticia';
import NetworkStatusHandler from './NetworkStatusHandler';
import CountdownOfferMobile from './CountdownOffer';
import CountdownOfferWeb from './CountdownOfferWeb';
import MaterialWeb from './MaterialWeb';
import Roadmap from './RoapMap';
import CategoryTests from './CategoryTest';
import CategoryPractico from './CategoryPracticos';


const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    backgroundColor: '#f5f5f5',
    minHeight: '100vh',
    overflowY: 'auto',
  },
  navbarTop: {
    padding: "6px",
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    position: 'fixed',
    top: 0,
    backgroundColor: '#9CAFB7',
    borderBottom: '1px solid #ccc',
    zIndex: 40,
    transition: 'top 0.3s', // Transición suave
  },
  navbarBottom: {
    padding: "6px",
    display: 'flex',
    justifyContent: 'space-around',
    width: '100%',
    position: 'fixed',
    bottom: 0,
    backgroundColor: '#9CAFB7',
    borderTop: '1px solid #ccc',
  },
  button: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    outline: 'none',
    textDecoration: 'none',
    color: 'inherit',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  activeButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#fff',
    color: '#000000',
    borderRadius: '5px',
    padding: '10px',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: '##fff',
    },
  },
  icon: {
    fontSize: '35px',
    marginBottom: '0px',
    marginTop: "5px",
    padding: "5px",
  },
  componentContainer: {
    width: '100%',
    marginTop: '100px', // Ajuste del margen superior
    marginBottom: '100px',
  },
};

const Menu = () => {
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('/corrector');
  const { state } = useGlobal(); // Accede solo al estado, sin necesidad de dispatch aquí
  const { cuerpo, user } = state; // Extraer el usuario del estado global
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const bannerVisible = !user.hasUsedFreeTrial || (user.bannerActive && new Date(user.dateBanner).getDate() <= 7);
    setShowBanner(bannerVisible);
  }, [user]);

  const getButtonStyle = (path) => {
    return selectedTab === path ? styles.activeButton : styles.button;
  };

  return (
    <div style={styles.container}>
      <div style={{ ...styles.navbarTop, top: showBanner ? '50px' : '0' }}>
      <Link to="/noticias" style={getButtonStyle("/noticias")}>
          <FaBell style={styles.icon} />
      </Link>
        <Link to="/roapmap" style={getButtonStyle("/roapmap")}>
          <FaTrophy style={styles.icon} />
        </Link>
        <Link to="/user" style={getButtonStyle("/user")}>
          <FaUser style={styles.icon} />
        </Link>
      </div>
      {showBanner && (
        windowWidth < 768 ? (
          <CountdownOfferMobile date={!user.hasUsedFreeTrial ? user.dateRegister : user.dateBanner} />
        ) : (
          <CountdownOfferWeb date={!user.hasUsedFreeTrial ? user.dateRegister : user.dateBanner} />
        )
      )}
      <div style={styles.navbarBottom}>
      <Link to="/audio" style={getButtonStyle("/audio")}>
          <FaHeadphones style={styles.icon} />
        </Link>
        {cuerpo.name === "agh" ? (
          <Link to="/categoryTest" style={getButtonStyle("/categoryTest")}>
            <FaTasks style={styles.icon} />
          </Link>
        ) : cuerpo.name === "TECH" ? (
          <Link to="/cuestionarios" style={getButtonStyle("/cuestionarios")}>
            <FaTasks style={styles.icon} />
          </Link>
        ) : (
          <Link to="/categoryTest" style={getButtonStyle("/categoryTest")}>
            <FaTasks style={styles.icon} />
          </Link>
        )}
        <Link to="/material" style={getButtonStyle("/material")}>
          <FaBook style={styles.icon} />
        </Link>
        <Link to="/categoryPracticos" style={getButtonStyle("/categoryPracticos")}>
          <FaDumbbell style={styles.icon} />
        </Link>
        <Link to="/chat" style={getButtonStyle("/chat")}>
          <FaCommentDots style={styles.icon} />
        </Link>
      </div>
      <div style={{
    ...styles.componentContainer,
    marginTop: showBanner ? '100px' : '70px',
  }}>
        <Routes>
          <Route path="/noticias" element={<NewsLetter />} />
          <Route path="/noticias/:id" element={<NewsLetter />} />
          <Route path="/correcciones" element={<Correcciones />} />
          <Route path='/material' element={<Materials />} />
          <Route path='/practicos' element={<Practicos />} />
          <Route path='/cuestionarios' element={<Cuestionario />} />
          <Route path='/user' element={<User />} />
          <Route path='/corrector' element={<MainScreen />} />
          <Route path='/CheckoutPage' element={<SubscriptionCard />} />
          <Route path='/confirm' element={<OrderConfirmationPage />} />
          <Route path='/cancel' element={<CancelPage />} />
          <Route path='/chat' element={<Chat />} />
          <Route path='/test' element={<ListTest />} />
          {/* <Route path='/testId' element={<ListTest />} /> */}
          <Route path='/' element={<Materials />} />
          <Route path='/retos' element={<Retos />} />
          <Route path='/audio' element={<AudioScroll />} />
          <Route path='/consultas-confirm' element={<OrderConfirmationConsultas />} />
          <Route path="/referral/:referralCode" element={<ReferralConfirmationPage />} />
          <Route path="/createNoticia" element={<CreateNoticia />} />
          <Route path="/MaterialWeb" element={<MaterialWeb/>} />
          <Route path="/roapmap" element={<Roadmap/>} />
          <Route path="/categoryTest" element={<CategoryTests/>} />
          <Route path="/categoryPracticos" element={<CategoryPractico/>} />
        </Routes>
      </div>
    </div>
  );
};

export default () => (
  <Router>
    <NetworkStatusHandler>
      <Menu />
    </NetworkStatusHandler>
  </Router>
);
