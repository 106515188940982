import React, { createContext, useContext, useReducer } from 'react';

// Inicializa el contexto
const GlobalContext = createContext();

// Define un reducer que manejará las acciones de actualización del estado global
const globalReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USER':
      return { ...state, user: action.payload };
    case 'UPDATE_USER':
        return { ...state, user: { ...state.user, ...action.payload }};
    case 'SET_CUERPO':
      return { ...state, cuerpo: action.payload };
    case 'UPDATE_USER_FIELD':
      return {
        ...state,
        user: {
          ...state.user,
          [action.payload.field]: action.payload.value,
        },
      };
    case 'UPDATE_TEST_COMPLETED':
      const updatedTestCompleted = state.user.testCompleted.map(test => {
        if (test.testId === action.payload.testId) {
          return { ...test, score: action.payload.score};
        }
        return test;
      });

      // Verifica si el testId ya existe en testCompleted
      const testExists = state.user.testCompleted.some(test => test.testId === action.payload.testId);

      if (!testExists) {
        updatedTestCompleted.push({ testId: action.payload.testId, score: action.payload.score });
      }

      return {
        ...state,
        user: {
          ...state.user,
          testCompleted: updatedTestCompleted,
        },
      };

    case 'UPDATE_PRACTICO_COMPLETED':
      const updatedPracticosCompleted = state.user.practicosCompleted.map(practico => {
        if (practico.practicoId === action.payload.practicoId) {
          return { ...practico, pdfUrl: action.payload.pdfUrl };
        }
        return practico;
      });

      // Verifica si el practicoId ya existe en practicosCompleted
      const practicoExists = state.user.practicosCompleted.some(practico => practico.practicoId === action.payload.practicoId);

      if (!practicoExists) {
        updatedPracticosCompleted.push({ practicoId: action.payload.practicoId, pdfUrl: action.payload.pdfUrl });
      }

      return {
        ...state,
        user: {
          ...state.user,
          practicosCompleted: updatedPracticosCompleted,
        },
      };

    case 'UPDATE_RETOS_COMPLETADOS':
      return {
        ...state,
        user: {
          ...state.user,
          retosCompletados: Object.keys(action.payload).filter(id => action.payload[id]),
        },
      };

    case 'UPDATE_RETOS_CANJEADOS':
      return {
        ...state,
        user: {
          ...state.user,
          retosCanjeados: action.payload,
        },
      };

    case 'UPDATE_HIGHLIGHTED_MATERIALS':
      const updatedHighlightedMaterials = state.user.highlightedMaterials.map(material => {
        if (material.materialId === action.payload.materialId) {
          return { ...material, content: action.payload.content };
        }
        return material;
      });

      const materialExists = state.user.highlightedMaterials.some(material => material.materialId === action.payload.materialId);

      if (!materialExists) {
        updatedHighlightedMaterials.push({ materialId: action.payload.materialId, content: action.payload.content });
      }

      return {
        ...state,
        user: {
          ...state.user,
          highlightedMaterials: updatedHighlightedMaterials,
        },
      };

    default:
      return state;
  }
};

// Define el componente proveedor del contexto
export const GlobalProvider = ({ children }) => {
  const initialState = {
    user: {
      retosCompletados: [],
      retosCanjeados: [],
      highlightedMaterials: [], // Añadir campo inicializado
    },
    cuerpo: null,
  };

  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalContext.Provider>
  );
};

// Hook personalizado para utilizar el contexto
export const useGlobal = () => useContext(GlobalContext);
