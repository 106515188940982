import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/practicos';

// const baseUrl = "http://localhost:3001/api/practicos"

const PracticoService = {
  uploadPractico: async (materialData, pdfFile) => {
    try {
      const formData = new FormData();
      formData.append('especialidad', materialData.especialidad);
      formData.append('title', materialData.title);
      formData.append('type', materialData.type);
      formData.append('file', pdfFile);

      const response = await axios.post(`${baseUrl}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });

      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al subir material';
    }
  },

  getAllPracticos: async () => {
    try {
      const response = await axios.get(`${baseUrl}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener todos los materiales';
    }
  },

  getPracticosById: async (materialId) => {
    try {
      const response = await axios.get(`${baseUrl}/${materialId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener material por ID';
    }
  },

  updatePracticos: async (materialId, updatedData) => {
    try {
      const response = await axios.put(`${baseUrl}/${materialId}`, updatedData);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar material';
    }
  },

  deletePracticos: async (materialId) => {
    try {
      const response = await axios.delete(`${baseUrl}/${materialId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al eliminar material';
    }
  },

  corregirPractico: async (userId, id, pregunta, respuesta, enunciado, criterio, cuerpo) => {
    try {
      const response = await axios.post(`${baseUrl}/${userId}/corregir`, { id, pregunta, respuesta, enunciado, criterio, cuerpo });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Error al corregir la respuesta';
    }
  },

  addOrUpdatePractico: async (userId, practicoData) => {
    try {
      const response = await axios.put(`${baseUrl}/nuevo/${userId}`, practicoData);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar el práctico';
    }
  }
};

export default PracticoService;
