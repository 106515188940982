// CheckoutService.js

import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/checkout'; // Reemplaza con la URL de tu backend
// const baseUrl = 'http://localhost:3001/api/checkout'; // Reemplaza con la URL de tu backend

const CheckoutService = {
  createCheckoutSession: async (userId, cantidad) => {
    try {
      const response = await axios.post(`${baseUrl}/create-payment-session`, {
        userId,
        cantidad,
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al crear sesión de pago';
    }
  },

  createSimplePaymentSession: async (cantidad, userId) => {
    try {
      const response = await axios.post(`${baseUrl}/${userId}/create-payment-simple`, {
        cantidad,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al crear la sesión de pago simple');
    }
  },

  createCheckoutSessionSubscription: async (userId, price, couponId, subscriptionCategory) => {
    try {
      const data = { userId, price, subscriptionCategory};
      if (couponId) {
        data.couponId = couponId;
      }
  
      // Asegúrate de incluir userId en el cuerpo de la solicitud
      const response = await axios.post(`${baseUrl}/create-checkout-session-suscription`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al crear sesión de pago para suscripción');
    }
  },
  

  createCheckoutSessionSubscriptionReanudar: async (userId) => {
    try {
      // Asegúrate de incluir userId en el cuerpo de la solicitud
      const response = await axios.post(`${baseUrl}/create-checkout-reanudar-session-subscription`, { userId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al crear sesión de pago para suscripción');
    }
  },

  createPortalSession: async (sessionId) => {
    try {
      const response = await axios.post(`${baseUrl}/create-portal-session`, {
        session_id: sessionId,
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al crear sesión del portal de facturación';
    }
  },

  handleCheckoutSuccess: async (sessionId) => {
    try {
      const response = await axios.post(`${baseUrl}/checkout-success?sessionId=${sessionId}`, null);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error después del pago';
    }
  },

  handleSubscriptionSuccess: async (sessionId) => {
    try {
      const response = await axios.post(`${baseUrl}/subscription-success`, {
        sessionId
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error después de la suscripción';
    }
  },

  cancelSubscription: async (userId) => {
    try {
      const response = await axios.post(`${baseUrl}/cancel-subscription`, {
        userId
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al cancelar la suscripción';
    }
  },

  continueSubscription: async (userId) => {
    try {
      const response = await axios.post(`${baseUrl}/continue-subscription`, { userId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al continuar con la suscripción');
    }
  },

  upgradeSubscription: async (userId, suscripcionId) => {
    try {
      const response = await axios.post(`${baseUrl}/upgrade`, {
        userId,
        suscripcionId
      });
      return response.data;
    } catch (error) {
      throw error.response?.data?.error || 'Error al actualizar la suscripción';
    }
  },

  verifyCheckoutSession: async (sessionId) => {
    try {
      const response = await axios.post(`${baseUrl}/verify-checkout-session`, { sessionId });
      console.log("verifyCheckout: ", response.data)
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.error || 'Error al verificar la sesión de checkout');
    }
  },
};



export default CheckoutService;
