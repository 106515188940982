import React, { useState, useEffect } from 'react';
import PracticosInterface from './PracticosInterface';
import PracticoInterfazWeb from './PracticoInterfaceWeb';
import UserService from '../services/User';
import { useGlobal } from '../context/GlobalContext';
import Practicos from './Practicos';
import { htmlToText } from 'html-to-text';


const PracticosWrapper = ({ desarrollo, multiple, duracion, id, userId, enunciado }) => {
  const { dispatch, state } = useGlobal();
  const [mode, setMode] = useState('test');
  const [respuestasUsuario, setRespuestasUsuario] = useState([]);
  const [correcciones, setCorrecciones] = useState({});
  const [showListPracticos, setShowListPracticos] = useState(false);
  const [isWideScreen, setIsWideScreen] = useState(window.innerWidth > 768);

  useEffect(() => {
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const preparePracticoData = (desarrollo, multiple, respuestasUsuario, correcciones) => {
    const convertHtmlToPlainText = (html) => {
      return htmlToText(html, {
        wordwrap: 130,
        preserveNewlines: true,
      });
    };
  
    const desarrolloData = desarrollo.map((pregunta, index) => {
      const correccionPlainText = convertHtmlToPlainText(correcciones[index] || 'Sin corrección');
      return `Pregunta: ${pregunta}
  Respuesta del Usuario: ${respuestasUsuario[index] || 'Sin respuesta'}
  Corrección: ${correccionPlainText}\n\n`;
    }).join('');
  
    const separateOptions = (optionsArray) => {
      if (!Array.isArray(optionsArray)) {
        return ['Opciones no disponibles'];
      }
      return optionsArray;
    };
  
    const multipleData = multiple.map((pregunta, index) => {
      const respuestaUsuario = respuestasUsuario[index + desarrollo.length];
      const respuestaCorrecta = pregunta.opcionCorrecta;
      const esCorrecta = respuestaUsuario === respuestaCorrecta ? 'Correcta' : 'Incorrecta';
  
      const opcionesArray = separateOptions(pregunta.opciones);
      const opcionesFormateadas = opcionesArray.join('\n- ');
  
      return `Pregunta: ${pregunta.pregunta}
  
  Opciones: 
  - ${opcionesFormateadas}
  
  Respuesta Seleccionada: ${respuestaUsuario || 'Sin respuesta'} (${esCorrecta})
  
  Respuesta Correcta: ${respuestaCorrecta}\n\n`;
    }).join('');
  
    return `${desarrolloData}\n${multipleData}`;
  };
  
  
  

  const updatePracticoCompleted = async (respuestas, correcciones) => {
    if (userId) {
      const practicoData = preparePracticoData(desarrollo, multiple, respuestas, correcciones);
      try {
        const response = await UserService.addOrUpdatePracticoCompleted(userId, id, practicoData);
        const updatedPractico = response.data.find(entry => entry.practicoId.toString() === id);
  
        if (updatedPractico) {
          const updatedPracticosCompleted = [
            ...state.user.practicosCompleted.filter(entry => entry.practicoId.toString() !== id),
            {
              practicoId: id,
              pdfUrl: updatedPractico.pdfUrl,
            },
          ];
  
          dispatch({
            type: 'UPDATE_PRACTICO_COMPLETED',
            payload: { practicosCompleted: updatedPracticosCompleted },
          });
        } else {
          console.error('Error: No se encontró el práctico actualizado en la respuesta.');
        }
      } catch (error) {
        console.error('Error al actualizar practicosCompleted:', error);
      }
    }
  };
  

  const handlePracticoSubmit = async (respuestas, nuevasCorrecciones) => {
    setRespuestasUsuario(respuestas);
    setCorrecciones(nuevasCorrecciones);
    setMode('review');

    await updatePracticoCompleted(respuestas, nuevasCorrecciones);
  };

  if (showListPracticos) {
    return <Practicos />;
  }

  const InterfaceComponent = isWideScreen ? PracticoInterfazWeb : PracticosInterface;

  return (
    <div>
      {mode === 'test' ? (
        <InterfaceComponent
          desarrollo={desarrollo}
          multiple={multiple}
          duracion={duracion}
          onSubmit={handlePracticoSubmit}
          mode={mode}
          enunciado={enunciado}
          id={id}
        />
      ) : (
        <>
          <InterfaceComponent
            desarrollo={desarrollo}
            multiple={multiple}
            duracion={duracion}
            mode="review"
            respuestasUsuario={respuestasUsuario}
            correcciones={correcciones}
            enunciado={enunciado}
            id={id}
          />
          <div>
            <button
              type="button"
              className="w-full text-white bg-gray-800 hover:bg-gray-900 py-2 rounded-lg shadow mt-4 mb-5" 
              onClick={() => setShowListPracticos(true)}
            >
              Volver al Listado
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default PracticosWrapper;

