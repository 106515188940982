import React, { useState, useEffect } from 'react';
import { useGlobal } from '../context/GlobalContext';
import CuerpoService from '../services/Cuerpo';
import FullScreenModal from './FullScreenModal';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import { FiLock } from 'react-icons/fi';

const MySwal = withReactContent(Swal);

const Materials = () => {
  const [materials, setMaterials] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { state } = useGlobal();
  const { user } = state;
  const [activeFullScreen, setActiveFullScreen] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [materialId, setMaterialId] = useState('');
  const [isMobile, setIsMobile] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMaterials = async () => {
      if (user && user.cuerpo) {
        MySwal.fire({
          title: 'Cargando materiales...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        try {
          const cuerpoData = await CuerpoService.getCuerpoById(user.cuerpo);
          setMaterials(cuerpoData.material || []);
          setTimeout(() => {
            MySwal.close();
          });
        } catch (error) {
          console.error('Error al obtener los materiales:', error);
          MySwal.fire('Error', 'Hubo un error al cargar los materiales. Por favor, intenta de nuevo.', 'error');
        }
      }
    };

    fetchMaterials();

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [user]);

  const handleSubscription = () => {
    navigate('/CheckoutPage', {
      state: {
        precioRefPro: 19.99, 
        precioDctoPro: 14.99, 
        precioRefVip: 31.99, 
        precioDctoVip: 24.99, 
        precioIdVip: 'price_1PNICRBodWVXIE1Gf3zldJrN', 
        priceIdBasic: 'price_1POJrFBodWVXIE1G1NdeQ8Fk', 
        priceIdPro: 'price_1POJogBodWVXIE1GBmtLCRgZ',
      }
    });
  };

  const formatContent = (text) => {
    if (!text) return null;

    const lines = text.split('\n');
    const formattedLines = lines.map((line) => {
      if (line.startsWith('- ')) {
        return `<li>${line.slice(2)}</li>`;
      } else if (/^\d+\.\s/.test(line)) {
        return `<p class="ml-4">${line}</p>`;
      } else if (line.trim() === '') {
        return `<br />`;
      } else {
        return `<p>${line}</p>`;
      }
    });

    return formattedLines.join('');
  };

  const filteredMaterials = searchText.length === 0
    ? materials
    : materials.filter(material =>
        material.title.toLowerCase().includes(searchText.toLowerCase()) ||
        material.comunidad.toLowerCase().includes(searchText.toLowerCase())
      );

  const handleMaterialClick = (material) => {
    setMaterialId(material.id);

    const highlightedMaterial = user.highlightedMaterials.find(highlight => highlight.materialId === material.id);
    const contentToShow = highlightedMaterial ? highlightedMaterial.content : formatContent(material.texto);
    setModalContent(contentToShow);

    if (isMobile) {
      setActiveFullScreen(true);
    } else {
      setTimeout(() => {
        navigate('/MaterialWeb', { state: { content: contentToShow, materialId: material.id } });
      }, 100);
    }
  };

  const closeModal = () => {
    setActiveFullScreen(false);
    setModalContent('');
    setMaterialId('');
  };

  return (
    <div className="flex flex-col items-center w-full min-h-screen p-6">
      {user && user.activeSus ? (
        <>
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Mis Materiales</h2>
          <input
            className="w-4/5 h-10 mb-6 border border-gray-300 rounded-md px-4"
            type="text"
            placeholder="Buscar Material..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <ul className="w-full flex flex-col items-center">
            {filteredMaterials.map((material) => (
              <li
                key={material.id}
                className="w-11/12 max-w-md shadow-lg rounded-lg overflow-hidden p-4 mb-6 transition duration-300 ease-in-out cursor-pointer bg-white"
                onClick={() => handleMaterialClick(material)}
              >
                <div>
                  <h3 className="text-lg font-semibold text-gray-800">{material.title}</h3>
                  <p className="text-sm text-gray-600">{material.date}</p>
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center p-6">
          <FiLock className="text-6xl mb-6" />
          <p className="text-xl text-center mb-6">Este contenido es exclusivo para suscriptores.</p>
          <button
            onClick={handleSubscription}
            className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg text-xl md:text-sm lg:text-base"
          >
            Ver Planes
          </button>
        </div>
      )}

      <FullScreenModal
        isOpen={activeFullScreen}
        onRequestClose={closeModal}
        content={modalContent}
        materialId={materialId}
        resaltadoVisible={true}
      />

      {activeFullScreen && (
        <button
          onClick={() => setActiveFullScreen(false)}
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-full shadow-lg z-50"
        >
          Cerrar
        </button>
      )}
    </div>
  );
};

export default Materials;
