import axios from 'axios';

const BASE_URL = 'https://opocheckchat.onrender.com/api/conversations'; // Cambia la URL base según la configuración de tu servidor

const ConversationService = {
  // Enviar una conversación al backend para ser guardada
  sendConversation: async (userId, answer, response) => {
    try {
      const res = await axios.post(`${BASE_URL}`, { userId, answer, response});
      return res.data;
    } catch (error) {
      console.error('Failed to update conversation:', error);
      throw error;
    }
  },

  // Obtener el historial de conversaciones de un usuario desde el backend
  getConversationHistory: async (userId) => {
    try {
      const response = await axios.get(`${BASE_URL}/${userId}`);
      return response.data;
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return null; // No se encontró ninguna conversación para este usuario
      }
      console.error('Failed to fetch conversation history:', error);
      throw error;
    }
  },

  addCreditosConsulta: async (userId, creditosConsulta, subscriptionCategory) => {
    try {
      const response = await axios.patch(`${BASE_URL}/${userId}/addCreditosConsulta`, {
        creditosConsulta,
        suscription: subscriptionCategory
      });
      return response.data;
    } catch (error) {
      console.error('Failed to add consultation credits:', error);
      throw error;
    }
  },
  

  addPurchaseToUser: async (userId, sessionId, cantidad) => {
    try {
      const response = await axios.put(`${BASE_URL}/${userId}/addOCompras`, {
        sessionId,
        cantidad,
      });
      return response.data;
    } catch (error) {
      console.error('Error al añadir compra:', error.response?.data?.message || 'Error desconocido');
      throw new Error(error.response?.data?.message || 'Error desconocido al añadir compra');
    }
  },

  updateCredits: async (userId, creditos) => {
    try {
      const res = await axios.patch(`${BASE_URL}/${userId}/updateCredits`, { creditos });
      return res.data;
    } catch (error) {
      console.error('Failed to update credits:', error);
      throw error;
    }
  }

};

export default ConversationService;
