import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/images'; // Reemplaza con la URL de tu backend

const ImageService = {
  uploadPdf: async (pdfFile) => {
    try {
      const formData = new FormData();
      formData.append('pdf', pdfFile);

      const response = await axios.post(`${baseUrl}/pdf`, formData);
      console.log(response.data)
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al subir PDF';
    }
  },
  uploadImages: async (imageFiles) => {
    try {
        const formData = new FormData();

        // Agregar todas las imágenes al FormData
        for (let i = 0; i < imageFiles.length; i++) {
            formData.append('files', imageFiles[i]);
        }

        // Enviar todas las imágenes juntas al backend
        const response = await axios.post(`${baseUrl}`, formData);
        console.log('Respuesta de subida de imágenes:', response.data);
        return response.data;
    } catch (error) {
        throw error.response.data.error || 'Error al subir imágenes';
    }
},

  getAllImages: async (token) => {
    try {
      const response = await axios.get(`${baseUrl}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener todas las imágenes';
    }
  },

  getImageById: async (imageId) => {
    try {
      const response = await axios.get(`${baseUrl}/${imageId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener imagen por ID';
    }
  },

  deleteImage: async (imageId, token) => {
    try {
      const response = await axios.delete(`${baseUrl}/${imageId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al eliminar imagen';
    }
  }
};

export default ImageService;
