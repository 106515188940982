import React, { useState, useEffect } from 'react';
import CheckoutService from '../services/checkout';
import AudioService from '../services/Audio'; // Asegúrate de tener este import
import { FiLock } from 'react-icons/fi';
import SubscriptionCard from './CheckoutPage';
import { useGlobal } from '../context/GlobalContext';

const AudioScroll = () => {
  const [searchText, setSearchText] = useState('');
  const [audios, setAudios] = useState([]); // Estado para almacenar los audios
  const { state, dispatch } = useGlobal(); // Accede al estado global
  const { user, cuerpo } = state;

  useEffect(() => {
    const fetchAudios = async () => {
      try {
        const fetchedAudios = await AudioService.getAllAudios();
        setAudios(fetchedAudios);
      } catch (error) {
        console.error("Error al obtener audios:", error);
      }
    };

    fetchAudios();
  }, []);

  const handleSubscription = async () => {
    try {
      const session = await CheckoutService.createCheckoutSessionSubscriptionReanudar(user.id);
      window.location.href = session.session_url;
    } catch (error) {
      console.log("Error al suscribirse:", error);
    }
  };

  const filteredAudios = audios.filter(audio =>
    audio.title.toLowerCase().includes(searchText.toLowerCase())
  );

  return (
    <div style={styles.audioListContainer}>
      {user && user.activeSus ? (
        <>
          <h3 style={styles.title}>Audiotemas</h3>
          <input
            style={styles.searchInput}
            type="text"
            placeholder="Buscar tu especialidad, comunidad..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          {filteredAudios.map(audio => (
            <div key={audio.id} style={styles.audioCard}>
              <div style={styles.audioTitle}>{audio.title}</div>
              <audio controls style={{ width: '100%' }}>
                <source src={audio.url} type="audio/mpeg" />
                Tu navegador no soporta el elemento de audio.
              </audio>
            </div>
          ))}
        </>
      ) : (
        <div style={styles.lockedContent}>
          <FiLock style={styles.lockIcon} />
          <p style={styles.lockMessage}>Este contenido es exclusivo para suscriptores.</p>
          <SubscriptionCard />
        </div>
      )}
    </div>
  );
};


// Asegúrate de incluir los estilos para lockedContent, lockIcon, lockMessage, y subscribeButton
const styles = {
  audioListContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '20px',
    padding: '20px',
  },

  title: {
    marginTop: '20px',
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '20px',
    color: '#333',
  },

  audioCard: {
    width: '90%',
    maxWidth: '600px',
    backgroundColor: 'white',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
    overflow: 'hidden',
    padding: '10px',
  },
  audioTitle: {
    fontSize: '18px',
    fontWeight: 'bold',
    marginBottom: '10px',
  },
  searchInput: {
    width: '80%',
    height: '40px',
    border: '1px solid #ccc',
    borderRadius: '5px',
    marginBottom: '20px',
    padding: '0 10px',
  },
  lockedContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px',
  },
  lockIcon: {
    fontSize: '48px', // Tamaño del emoji
    marginBottom: '20px',
  },
  lockMessage: {
    fontSize: '18px',
    textAlign: 'center',
    marginBottom: '20px',
  },
  subscribeButton: {
    padding: '10px 20px',
    fontSize: '16px',
    color: '#fff',
    backgroundColor: '#007bff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default AudioScroll;
