import React, { useState, useEffect } from 'react';
import { FiLock } from 'react-icons/fi';
import CuerpoService from '../services/Cuerpo';
import { useGlobal } from '../context/GlobalContext';
import SubscriptionCard from './CheckoutPage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';

const MySwal = withReactContent(Swal);

const Cuestionario = () => {
  const [cuestionarios, setCuestionarios] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { state } = useGlobal();
  const { user } = state;

  useEffect(() => {
    const fetchCuestionarios = async () => {
      if (user && user.cuerpo) {
        MySwal.fire({
          title: 'Cargando cuestionarios...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        try {
          const cuerpoData = await CuerpoService.getCuerpoById(user.cuerpo);
          setCuestionarios(cuerpoData.cuestionario || []);
          setTimeout(() => {
            MySwal.close();
          }, 2000);
        } catch (error) {
          console.error('Error al obtener los cuestionarios:', error);
          MySwal.fire('Error', 'Hubo un error al cargar los cuestionarios. Por favor, intenta de nuevo.', 'error');
        }
      }
    };

    fetchCuestionarios();
  }, [user]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredCuestionarios = searchText.length === 0
    ? cuestionarios
    : cuestionarios.filter(cuestionario =>
        cuestionario.title.toLowerCase().includes(searchText.toLowerCase())
      );

  return (
    <div className="flex flex-col items-center p-6 max-w-3xl mx-auto bg-gray-100">
      {user && user.activeSus ? (
        <>
          <h2 className="text-2xl font-bold text-gray-800 mb-6">Mis Cuestionarios</h2>
          <input
            className="w-4/5 h-10 mb-6 border border-gray-300 rounded-md px-4"
            type="text"
            placeholder="Buscar cuestionario..."
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)}
          />
          <ul className="w-full flex flex-col items-center">
            {filteredCuestionarios.map((cuestionario) => (
              <li
                key={cuestionario.id}
                className="w-11/12 max-w-md bg-white shadow-lg rounded-lg overflow-hidden p-4 mb-6 transition duration-300 ease-in-out hover:bg-gray-50 cursor-pointer"
                onClick={() => window.open(cuestionario.url, '_blank')}
              >
                <p className="text-lg font-semibold text-gray-800">{cuestionario.title}</p>
                <p className="text-sm text-gray-600">Fecha: {formatDate(cuestionario.date)}</p>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center p-6">
          <FiLock className="text-6xl mb-6" />
          <p className="text-xl text-center mb-6">Este contenido es exclusivo para suscriptores.</p>
          <SubscriptionCard />
        </div>
      )}
    </div>
  );
};

export default Cuestionario;
