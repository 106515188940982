// sessionService.js
import axios from 'axios';

const BASE_URL = 'https://opocheck.onrender.com/api/sessions'; // Asegúrate de reemplazar 'https://tu-dominio.com' con la URL de tu servidor

const sessionService = {
  createSession: async (sessionId, userId) => {
    try {
      const response = await axios.post(`${BASE_URL}/`, { sessionId, userId });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al crear la sesión');
    }
  },

  getAllSessions: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener las sesiones');
    }
  },

  getSessionById: async (stripeSessionid) => {
    try {
      const response = await axios.get(`${BASE_URL}/${stripeSessionid}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener la sesión');
    }
  },

  markSessionAsUsed: async (id) => {
    try {
      const response = await axios.patch(`${BASE_URL}/${id}/use`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al marcar la sesión como usada');
    }
  },

  deleteSession: async (id) => {
    try {
      const response = await axios.delete(`${BASE_URL}/${id}`);
      return response.data; // Puede ser útil devolver algo de la respuesta para confirmar la eliminación
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al eliminar la sesión');
    }
  },

  markSessionAsUsedById: async (id) => {
    try {
      const response = await axios.patch(`${BASE_URL}/${id}/markAsUsed`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al marcar la sesión como utilizada');
    }
  },
};

export default sessionService;
