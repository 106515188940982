import React, { useState, useEffect } from 'react';
import { FiFileText, FiImage, FiX, FiPlus, FiCheck } from 'react-icons/fi';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { v4 as uuidv4 } from 'uuid';
import ImageService from '../services/Image';
import AnswerService from '../services/Answer';
import IAResponseService from '../services/IaRes';
import DocumentService from '../services/Document';
import { useGlobal } from '../context/GlobalContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const MainScreen = () => {
    const [imagesUploaded, setImagesUploaded] = useState(false);
    const [rawImages, setRawImages] = useState([]);
    const [filePreviews, setFilePreviews] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [updateCount, setUpdateCount] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [file, setFile] = useState(null);
    const [pdfFileName, setPdfFileName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isConfirm, setIsConfirm] = useState(false);
    const [correctionSent, setCorrectionSent] = useState(false);
    const [awaitCorrection, setAwaitCorrection] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const { state } = useGlobal();
    const { cuerpo, user } = state;
    const navigate = useNavigate();

    const checkConsultantAndProceed = (callback) => {
        if (user.credits <= 0) {
            MySwal.fire({
                title: 'Vaya, te has quedado sin correcciones',
                text: 'Puedes adquirir alguna más si quieres en tu perfil de usuario.',
                icon: 'info',
                showCancelButton: true,
                confirmButtonText: 'Ir a perfil',
                cancelButtonText: 'Cancelar'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/user');
                }
            });
        } else {
            callback();
        }
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const cleanFileName = (fileName) => {
        return fileName.replace(/[^\w\d-_]/g, ""); // Eliminar caracteres especiales excepto letras, dígitos, guiones y guiones bajos
    };

    const handleFileChange = async (event) => {
        checkConsultantAndProceed(async () => {
            const file = event.target.files[0]; // Obtener el primer archivo de la lista
            if (file.type === 'application/pdf') {
                try {
                    setIsLoading(true);
                    MySwal.fire({
                        title: 'Subiendo archivo...',
                        html: (
                            <div className="flex flex-col items-center justify-center">
                                <Circles color="#9CAFB7" height={80} width={80} />
                            </div>
                        ),
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        showConfirmButton: false,
                    });
                    const response = await ImageService.uploadPdf(file);
                    setFile(response); // Almacenar el resultado en el estado
                    setPdfFileName(cleanFileName(file.name));  // Almacenar el nombre del archivo PDF
                    setErrorMessage('');
                    setIsLoading(false);
                    MySwal.close();
                } catch (error) {
                    setErrorMessage('Error al subir el archivo PDF');
                    setIsLoading(false); // Indicar que la carga ha finalizado con error
                    MySwal.close();
                }
            } else {
                handleAddImage(event);
            }
        });
    };

    const handleAddImage = async (event) => {
        checkConsultantAndProceed(async () => {
            const files = event.target.files;

            if (!files || files.length === 0) {
                setErrorMessage('No se seleccionaron imágenes.');
                setShowAlert(true);
                return;
            }

            try {
                setIsLoading(true);
                MySwal.fire({
                    title: 'Procesando imágenes...',
                    html: (
                        <div className="flex flex-col items-center justify-center">
                            <Circles color="#9CAFB7" height={80} width={80} />
                        </div>
                    ),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                });

                for (const file of files) {
                    if (file.type.startsWith('image')) {
                        const id = uuidv4();
                        setRawImages(prevImages => [...prevImages, { id: id, file }]);

                        const reader = new FileReader();
                        reader.onload = (event) => {
                            const dataURL = event.target.result;
                            setFilePreviews(prevFilePreviews => [
                                ...prevFilePreviews,
                                { id: id, data: dataURL }
                            ]);
                        };
                        reader.readAsDataURL(file);
                    } else {
                        console.warn(`El archivo "${file.name}" no es una imagen y será ignorado...`);
                    }
                }

                if (rawImages.length === 0) {
                    setErrorMessage('No se seleccionaron imágenes válidas.');
                    setShowAlert(true);
                    return;
                }

                setIsLoading(false);
                MySwal.close();
            } catch (error) {
                console.error('Error al procesar las imágenes:', error);
                setErrorMessage('Error al procesar las imágenes.');
                setShowAlert(true);
                setIsLoading(false);
                MySwal.close();
            }
        });
    };

    const handleConfirmImage = async () => {
            if (isConfirm && rawImages.length > 0) {
                await uploadImages();
                setImagesUploaded(true);
            } else {
                setErrorMessage("Error al confirmar las imágenes");
                setImagesUploaded(false);
            }
    };

    const uploadImages = async () => {
        try {
            setIsLoading(true);
            MySwal.fire({
                title: 'Subiendo imágenes...',
                html: (
                    <div className="flex flex-col items-center justify-center">
                        <Circles color="#9CAFB7" height={80} width={80} />
                    </div>
                ),
                allowOutsideClick: false,
                allowEscapeKey: false,
                showConfirmButton: false,
            });
            const response = await ImageService.uploadImages(rawImages.map(image => image.file));
            setFile(response);
            setRawImages([]);
            setIsLoading(false);
            MySwal.close();
        } catch (error) {
            console.error('Error al procesar las imágenes:', error);
            setErrorMessage('Error al procesar las imágenes.');
            setShowAlert(true);
            setIsLoading(false);
            MySwal.close();
        } finally {
            setFilePreviews([]);
        }
    };

    useEffect(() => {
        handleConfirmImage();
    }, [isConfirm]);

    const handleCancelPreview = (id) => {
        const newFilePreviews = filePreviews.filter(file => file.id !== id);
        setFilePreviews(newFilePreviews);

        const newRawImages = rawImages.filter(image => image.id !== id);
        setRawImages(newRawImages);

        setUpdateCount(prevCount => prevCount + 1);
        setErrorMessage('');

        if (newFilePreviews.length === 0) {
            setShowAlert(false);
        } else {
            const isPdf = newFilePreviews.some(file => file.data.startsWith('data:application/pdf'));
            const isImage = newFilePreviews.some(file => !file.data.startsWith('data:application/pdf'));

            if (isPdf && isImage) {
                setErrorMessage('No puedes subir un PDF si ya has subido imágenes');
                setShowAlert(true);
            } else if (isPdf) {
                setShowAlert(false);
            } else {
                setShowAlert(false);
            }
        }
    };

    const prompt = (correccion, select) => {
        let selectedText;
        switch (select) {
            case "tema":
                selectedText = correccion.correctorTema;
                break;
            case "practico":
                selectedText = correccion.correctorPractico;
                break;
            case "simulacro":
                selectedText = correccion.correctorSimulacro;
                break;
            default:
                selectedText = "Selecciona una opción válida";
        }
        return selectedText;
    };

    const handleCorrector = async (event) => {
        event.preventDefault();
        checkConsultantAndProceed(async () => {
            setIsLoading(true);
            setAwaitCorrection(true);

            if (!file || !user.id) {
                setIsLoading(false);
                return;
            }

            try {
                const corrector = prompt(cuerpo, selectedOption);
                const answer = await AnswerService.createAnswer(user.id, file._id, corrector);

                // Mostrar la animación de carga con SweetAlert2
                MySwal.fire({
                    title: 'Esto puede tardar algunos minutos',
                    html: (
                        <div className="flex flex-col items-center justify-center">
                            <Circles color="#9CAFB7" height={80} width={80} />
                        </div>
                    ),
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    showConfirmButton: false,
                });

                await processIAResponseAndGenerateDocument(answer._id, pdfFileName);
            } catch (error) {
                console.error('Error en el flujo de corrección:', error);
                MySwal.close();
            } finally {
                setIsLoading(false);
            }
        });
    };

    const processIAResponseAndGenerateDocument = async (answerId, fileName) => {
        try {
            const iaResponse = await IAResponseService.processIAResponse(answerId);
            setTimeout(async () => {
                if (iaResponse.iaResponse._id && fileName) {
                    try {
                        const document = await DocumentService.generateDocument(iaResponse.iaResponse._id, fileName);
                        setAwaitCorrection(false);
                        setCorrectionSent(true);
                        MySwal.close();
                    } catch (error) {
                        console.error('Error al actualizar el nombre del documento:', error);
                        MySwal.close();
                    }
                } else {
                    console.log("IaResponse._id no disponible después de esperar o fileName es indefinido");
                    MySwal.close();
                }
            }, 5000);
        } catch (error) {
            console.error('Error al procesar la respuesta de IA o generar el documento:', error);
            MySwal.close();
        }
    };

    return (
        <div className="flex flex-col items-center p-5">
            {filePreviews.length > 0 ? (
                <div className="relative">
                    <Carousel key={updateCount} showArrows={false} showThumbs={true} showStatus={false} swipeable={true}>
                        {filePreviews.map((preview) => (
                            <div key={preview.id} className="relative">
                                <img src={preview.data} alt={`File Preview ${preview.id}`} className="max-w-xs max-h-xs" />
                                <button onClick={() => handleCancelPreview(preview.id)} className="absolute top-2 right-2 text-red-600">
                                    <FiX />
                                </button>
                            </div>
                        ))}
                    </Carousel>
                    <button className="absolute top-32 right-10 bg-gray-200 p-2 rounded-full">
                        <FiPlus />
                        <input
                            type="file"
                            accept="image/jpeg, image/png"
                            onChange={handleAddImage}
                            multiple
                            className="absolute opacity-0 top-0 left-0 w-full h-full cursor-pointer"
                            id="image-upload"
                        />
                    </button>
                    <button className="absolute top-40 right-10 bg-gray-200 p-2 rounded-full" onClick={() => setIsConfirm(true)}>
                        <FiCheck />
                    </button>
                </div>
            ) : (
                <div>
                    <p>
                        {awaitCorrection
                            ? <>Esto puede tardar unos minutos</>
                            : correctionSent
                                ? 'Tu corrección ha sido enviada a tu email y también la tienes en "Mis Correcciones"'
                                : pdfFileName
                                    ? `El PDF "${pdfFileName}" se ha subido correctamente`
                                    : imagesUploaded
                                        ? 'Se han subido correctamente las imágenes'
                                        : 'Sube tu documento o imágenes'
                        }
                    </p>
                </div>
            )}

            <div className="flex justify-center mb-5">
                <input type="file" accept=".pdf" onChange={handleFileChange} className="hidden" id="pdf-upload" />
                <label htmlFor="pdf-upload" className="flex flex-col items-center justify-center w-48 h-32 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer mx-2 transition-colors hover:bg-gray-100">
                    <FiFileText />
                    Subir PDF
                </label>
                <input type="file" accept="image/jpeg, image/png" onChange={handleAddImage} multiple className="hidden" id="image-upload" />
                <label htmlFor="image-upload" className="flex flex-col items-center justify-center w-48 h-32 border-2 border-dashed border-gray-400 rounded-lg cursor-pointer mx-2 transition-colors hover:bg-gray-100">
                    <FiImage />
                    Subir Imágenes
                </label>
            </div>
            <form className="flex flex-col items-center w-full" onSubmit={handleCorrector}>
                <select required value={selectedOption} onChange={handleOptionChange} className="min-w-[150px] h-10 border border-gray-400 rounded mb-3">
                    <option value="">Opción</option>
                    <option value="tema">Temas</option>
                    <option value="practico">Prácticos</option>
                    <option value="simulacro">Simulacro</option>
                </select>
                <button type="submit" className="w-48 bg-[#9CAFB7] text-black py-2 rounded-lg" disabled={isLoading}>
                    Corregir
                </button>
            </form>

            {showAlert && (
                <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center">
                    <div className="bg-white p-5 rounded-lg text-center">
                        <p className="text-red-600">{errorMessage}</p>
                        <button onClick={() => setShowAlert(false)} className="bg-gray-200 p-2 rounded-lg mt-3">Cerrar</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MainScreen;
