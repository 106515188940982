import React, { useState, useEffect } from 'react';

const ThinkingAnimation = () => {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + '.' : '.'));
    }, 500); // Cambia cada 500ms

    return () => clearInterval(interval);
  }, []);

  return <span>{dots}</span>;
};

export default ThinkingAnimation;
 