import React, { useState } from 'react';
import UserService from '../services/User';
import Login from './Login';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const NewPassword = ({ email }) => {
  const [code, setCode] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const [showLogin, setShowLogin] = useState(false);

  const handleResetPassword = async () => {
    if (password !== repeatPassword) {
      setError('Las contraseñas no coinciden.');
      return;
    }

    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+}{":;\'?/><,.\\[\\]\\\\]).{8,}$');
    if (!regex.test(password)) {
      setError('La contraseña debe tener al menos una letra minúscula, una letra mayúscula, un número, un carácter especial y tener una longitud mínima de 8 caracteres.');
      return;
    }

    try {
      await UserService.resetPasswordWithCode(code, password);
      setSuccess(true);
      MySwal.fire('¡Éxito!', 'Tu contraseña ha sido cambiada correctamente.', 'success');
    } catch (error) {
      setError(error.response?.data?.error || 'Error al restablecer contraseña. Por favor, inténtelo de nuevo más tarde.');
    }
  };

  const handleLogin = () => {
    setShowLogin(true);
  };

  return (
    <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
      {(!success && !showLogin) ? (
        <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
          <h2 className="mb-6 text-2xl font-semibold text-center text-gray-800">Restablecer Contraseña</h2>
          <input
            className="w-full h-12 px-3 border border-gray-300 rounded-md"
            type="text"
            placeholder="Código de verificación"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <input
            className="w-full h-12 px-3 border border-gray-300 rounded-md mt-4"
            type="password"
            placeholder="Nueva contraseña"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <input
            className="w-full h-12 px-3 border border-gray-300 rounded-md mt-4"
            type="password"
            placeholder="Repetir contraseña"
            value={repeatPassword}
            onChange={(e) => setRepeatPassword(e.target.value)}
            required
          />
          <button
            className="w-full h-12 text-white bg-blue-500 rounded-md hover:bg-blue-600 mt-4"
            onClick={handleResetPassword}
          >
            Restablecer Contraseña
          </button>
          {error && <p className="text-red-500 mt-4">{error}</p>}
          <p className="mt-4 text-center text-blue-600 cursor-pointer" onClick={handleLogin}>
            ¿Quieres iniciar sesión nuevamente?
          </p>
        </div>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default NewPassword;
