import React, { useState, useRef, useMemo } from 'react';
import Modal from 'react-modal';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useGlobal } from '../context/GlobalContext';
import UserService from '../services/User';

const MySwal = withReactContent(Swal);

Modal.setAppElement('#root'); // Asegúrate de que esto apunta al elemento principal de tu aplicación

const FullScreenModal = ({ isOpen, onRequestClose, content, materialId, resaltadoVisible = false }) => {
  const { state, dispatch } = useGlobal();
  const { user } = state;

  const [modalContent, setModalContent] = useState(content);
  const [highlightMode, setHighlightMode] = useState(false);
  const [highlightColor, setHighlightColor] = useState('#FFFF00');

  const contentEditableRef = useRef(null);

  const formatContent = (text) => {
    const lines = text.split('\n');
    const formattedLines = lines.map((line, index) => {
      if (line.startsWith('- ')) {
        return `<li key=${index}>${line.slice(2)}</li>`;
      } else if (/^\d+\.\s/.test(line)) {
        return `<p key=${index} class="ml-4">${line}</p>`;
      } else if (line.trim() === '') {
        return `<br key=${index} />`;
      } else {
        return `<p key=${index}>${line}</p>`;
      }
    });

    return formattedLines.join('');
  };

  const formattedContent = useMemo(() => {
    // Verifica si el contenido ya tiene formato HTML
    if (/<\/?[a-z][\s\S]*>/i.test(content)) {
      return content;
    }
    return formatContent(content);
  }, [content]);

  const handleHighlightText = () => {
    const selection = window.getSelection();
    if (!selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    if (!range || !range.cloneContents) return;

    const clonedSelection = range.cloneContents();
    const span = document.createElement('span');
    span.style.backgroundColor = highlightColor;

    // Verifica si el contenido seleccionado es texto puro
    if (range.startContainer === range.endContainer && range.startContainer.nodeType === Node.TEXT_NODE) {
      span.textContent = selection.toString();
      range.deleteContents();
      range.insertNode(span);
    } else {
      // Aplica el resaltado solo a los nodos de texto puro
      const nodes = clonedSelection.childNodes;
      nodes.forEach((node) => {
        if (node.nodeType === Node.TEXT_NODE) {
          const textNode = document.createElement('span');
          textNode.style.backgroundColor = highlightColor;
          textNode.textContent = node.textContent;

          range.deleteContents();
          range.insertNode(textNode);
        }
      });
    }

    const newContent = contentEditableRef.current.innerHTML;
    setModalContent(newContent);
  };

  const handleSaveHighlightedMaterial = async () => {
    await UserService.saveHighlightedMaterial(user.id, materialId, modalContent);
    dispatch({
      type: 'UPDATE_HIGHLIGHTED_MATERIALS',
      payload: { materialId: materialId, content: modalContent },
    });
    setHighlightMode(false);
    MySwal.fire('¡Guardado!', 'Tus cambios resaltados han sido guardados.', 'success');
  };

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel="Práctico Enunciado"
      className="fixed inset-0 flex items-center justify-center p-0"
      overlayClassName="fixed inset-0 bg-black bg-opacity-50"
    >
      <div className="relative flex flex-col h-full w-full bg-white items-center justify-center">
        <div className="flex-grow overflow-auto p-4 text-sm leading-relaxed text-justify max-w-screen-md" style={{ maxWidth: '750px', marginTop: highlightMode ? "170px" : "120px" }}>
          <div
            ref={contentEditableRef}
            dangerouslySetInnerHTML={{ __html: modalContent || formattedContent }}
            className="space-y-4"
          />
        </div>
        {highlightMode && (
            <div className="absolute top-4 left-4" style={{marginTop: "90px"}}>
              <label className="block mb-2">Selecciona color:</label>
              <div className="flex space-x-2">
                <button
                  className="w-8 h-8 rounded-full bg-yellow-200 border-2 border-yellow-300"
                  onClick={() => setHighlightColor('#FFFF99')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-green-200 border-2 border-green-300"
                  onClick={() => setHighlightColor('#B3FFCC')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-red-200 border-2 border-red-300"
                  onClick={() => setHighlightColor('#FFCCCC')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-blue-200 border-2 border-blue-300"
                  onClick={() => setHighlightColor('#CCFFFF')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-purple-200 border-2 border-purple-300"
                  onClick={() => setHighlightColor('#E6CCFF')}
                />
                <button
                  className="w-8 h-8 rounded-full bg-white border-2 border-gray-300"
                  onClick={() => setHighlightColor('#FFFFFF')}
                />
              </div>
            </div>
          )}
        {resaltadoVisible && (
          <div className="fixed bottom-20 left-4 flex flex-col items-center space-y-2 z-50">
            {highlightMode && (
              <>
                <button
                  onClick={handleHighlightText}
                  className="bg-yellow-500 text-white p-2 rounded-full shadow-lg"
                >
                  Aplicar Resaltado
                </button>
                <button
                  onClick={handleSaveHighlightedMaterial}
                  className="bg-green-500 text-white p-2 rounded-full shadow-lg"
                >
                  Guardar Cambios
                </button>
              </>
            )}
            {!highlightMode && (
              <button
                onClick={() => setHighlightMode(true)}
                className="bg-blue-500 text-white p-2 rounded-full shadow-lg"
              >
                Modo Resaltado
              </button>
            )}
          </div>
        )}
      </div>
    </Modal>
  );
};

export default FullScreenModal;
