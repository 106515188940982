import React, { useEffect, useState } from 'react';
import { useGlobal } from '../context/GlobalContext';
import DocumentService from '../services/Document';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import { v4 as uuidv4 } from 'uuid';

const MySwal = withReactContent(Swal);

const Correcciones = () => {
  const [searchText, setSearchText] = useState('');
  const { state } = useGlobal();
  const { user } = state;
  const [documents, setDocuments] = useState([]);

  const getResponse = async () => {
    try {
      if (user && user.responses) {
        MySwal.fire({
          title: 'Cargando correcciones...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        try {
          const documentPromises = user.responses
            .filter(response => response.document) // Filtrar las respuestas con documento definido
            .map(async response => {
              const document = await DocumentService.getDocumentById(response.document);
              return { ...document, date: response.date };
            });
          const documents = await Promise.all(documentPromises);
          setDocuments(documents);
          setTimeout(() => {
            MySwal.close();
          }, 2000); // Añade un retraso de 2 segundos antes de cerrar la animación
        } catch (error) {
          console.error('Error al obtener las correcciones:', error);
          MySwal.fire('Error', 'Hubo un error al cargar las correcciones. Por favor, intenta de nuevo.', 'error');
        }
      } else {
        setDocuments([]);
      }
    } catch (error) {
      console.error("Error al obtener la respuesta:", error);
    }
  };

  useEffect(() => {
    if (user && user.responses) {
      getResponse();
    }
  }, [user]);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredDocuments = documents.filter(doc =>
    doc.name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleDownload = (url) => {
    window.open(url, '_blank');
  };

  return (
    <div className="flex flex-col items-center p-6 max-w-3xl mx-auto bg-gray-100 min-h-screen">
      <h2 className="text-2xl font-bold text-gray-800 mb-6">Mis Correcciones</h2>
      <input
        className="w-4/5 h-10 mb-6 border border-gray-300 rounded-md px-4"
        type="text"
        placeholder="Nombre del archivo..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <ul className="w-full flex flex-col items-center">
        {filteredDocuments.map(doc => (
          <li
            key={uuidv4()}
            className="w-11/12 max-w-md bg-white shadow-lg rounded-lg overflow-hidden p-4 mb-6 transition duration-300 ease-in-out cursor-pointer"
            onClick={() => handleDownload(doc.url)}
          >
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-lg font-semibold text-gray-800">{doc.name}</h3>
                <p className="text-sm text-gray-600">Fecha: {formatDate(doc.date)}</p>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Correcciones;
