import React, { useState, useEffect } from 'react';
import { FaRegClock } from 'react-icons/fa';
import preguntasService from '../services/Pregunta';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';

const MySwal = withReactContent(Swal);

const TestInterface = ({ preguntas, duracion, onSubmit, mode = 'test', respuestasUsuario = [] }) => {
  const [respuestas, setRespuestas] = useState([]);
  const [tiempoRestante, setTiempoRestante] = useState(duracion);
  const [submitted, setSubmitted] = useState(false);
  const [preguntasData, setPreguntasData] = useState([]);

  useEffect(() => {
    const getPreguntas = async () => {
      try {
        MySwal.fire({
          title: 'Cargando...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        const data = await Promise.all(
          preguntas.map(preguntaId => preguntasService.getPreguntaById(preguntaId))
        );
        setPreguntasData(data);
        setRespuestas(Array(data.length).fill(null));
        setTimeout(() => {
          MySwal.close();
        }, 3000);
      } catch (error) {
        console.error('Error al obtener las preguntas:', error);
        MySwal.fire('Error', 'Hubo un error al cargar las preguntas. Por favor, intenta de nuevo.', 'error');
      }
    };

    if (preguntas.length > 0) {
      getPreguntas();
    }
  }, [preguntas]);

  useEffect(() => {
    if (mode === 'test') {
      const timerId = tiempoRestante > 0 && setInterval(() => {
        setTiempoRestante(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [tiempoRestante, mode]);

  const handleOptionChange = (index, opcion) => {
    const nuevasRespuestas = [...respuestas];
    nuevasRespuestas[index] = opcion;
    setRespuestas(nuevasRespuestas);
    localStorage.setItem('respuestas_test_' + (preguntas[0] || new Date().getTime()), JSON.stringify(nuevasRespuestas));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);
    onSubmit(respuestas, preguntasData);
  };

  const formatTime = () => {
    const minutos = Math.floor(tiempoRestante / 60);
    const segundos = tiempoRestante % 60;
    return `${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
  };

  if (!preguntasData || preguntasData.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="text-center text-xl">
          Cargando
          <Circles color="#9CAFB7" height={80} width={80} />
        </div>
      </div>
    );
  }

  return (
    <>
      {mode === 'test' && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 bg-white shadow-md rounded-md p-1 flex items-center justify-center gap-2" style={{marginTop: "50px"}}>
          <FaRegClock />
          <span>{formatTime()}</span>
        </div>
      )}
      <div className="flex justify-center items-center w-full min-h-screen">
        <div className="pb-8 w-full max-w-4xl">
          <form onSubmit={handleSubmit} className="space-y-6 w-full">
            {preguntasData.map((pregunta, index) => (
              <div key={index} className="w-full p-4">
                <p className="text-lg text-gray-700 mb-4">{index + 1}. {pregunta.pregunta}</p>
                {pregunta.opciones.map((opcion, i) => (
                  <label key={i} className={`block p-3 mb-2 cursor-pointer border hover:border-[#9CAFB7] ${mode === 'review' ? respuestasUsuario[index] === opcion ? opcion !== pregunta.opcionCorrecta ? 'bg-red-100 border-red-300' : 'bg-green-100 border-green-300' : opcion === pregunta.opcionCorrecta ? 'bg-green-100 border-green-300' : 'border-gray-300' : 'border-gray-300'} rounded-lg transition duration-200`}>
                    <input
                      type="radio"
                      name={`pregunta_te${index}`}
                      value={opcion}
                      checked={respuestas[index] === opcion}
                      onChange={() => handleOptionChange(index, opcion)}
                      disabled={mode !== 'test' || submitted || tiempoRestante <= 0}
                      className="mr-2 cursor-pointer"
                    />
                    {opcion}
                  </label>
                ))}
                {mode === 'review' && (
                  <div className="mt-4 italic text-gray-600 text-base">
                   <p> <strong>Sección:</strong> {pregunta.tema}</p>
                   <p><strong>Justificación de la respuesta:</strong> {pregunta.texto}</p>
                  </div>
                )}
              </div>
            ))}
            {mode === 'test' && (
              <button
                type="submit"
                className="w-full text-white bg-gray-800 hover:bg-gray-900 py-2 rounded-lg shadow mt-4" 
              >
                Enviar Respuestas
              </button>
            )}
          </form>
        </div>
      </div>
      {mode === 'test' && tiempoRestante <= 0 && !submitted && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-600 text-white py-2 px-4 rounded-full shadow-lg">
          El tiempo ha terminado.
        </div>
      )}
    </>
  );
};

export default TestInterface;
