import React, { useState, useEffect } from 'react';
import UserService from '../services/User';
import CuerpoService from '../services/Cuerpo';
import Cookies from 'js-cookie';
import { FaCalculator } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CuerpoSelector = () => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const cookieUsuario = Cookies.get('usuario');
    if (cookieUsuario) {
      const datosUsuario = JSON.parse(cookieUsuario);
      setUserId(datosUsuario.id);
    }
  }, []);

  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message, callback) => toast.success(message, { onClose: callback });

  const handleCuerpoSelect = async (shortName) => {
    try {
      toast.info('Seleccionando cuerpo...', { autoClose: false, toastId: 'loadingToast' });

      const cuerpoData = await CuerpoService.getCuerpoByName(shortName);
      if (!cuerpoData) {
        toast.dismiss('loadingToast');
        notifyError('Cuerpo no encontrado. Por favor, intenta de nuevo.');
        return;
      }

      const user = await UserService.getUserById(userId);
      if (user && user.cuerpo === cuerpoData._id) {
        toast.dismiss('loadingToast');
        notifySuccess('Ya tienes este cuerpo seleccionado.', () => {
          window.location.reload(); // Recargar la página
        });
        return;
      }

      await UserService.addCuerpoId(userId, cuerpoData._id);
      toast.dismiss('loadingToast');
      notifySuccess('Cuerpo seleccionado con éxito.', () => {
        window.location.reload(); // Recargar la página
      });
    } catch (error) {
      console.error('Error al seleccionar el cuerpo:', error);
      toast.dismiss('loadingToast');
      notifyError('Hubo un error al seleccionar el cuerpo. Por favor, intenta de nuevo.');
    }
  };

  const categories = [
    { icon: <FaCalculator />, name: 'Agente de Hacienda (Acceso libre y Promoción Interna)', shortName: 'AGH', color: '#9CAFB7' },
    { icon: <FaCalculator />, name: 'Técnico de Hacienda (proximamente)', shortName: 'THC', color: '#E6E0D4' },
    // ... Las demás categorías ...
  ];

  return (
    <div className="flex flex-col items-center justify-center p-6 bg-gray-100 min-h-screen">
      <ToastContainer />
      <h1 className="text-2xl font-bold mb-4">¿Qué oposiciones quieres preparar?</h1>
      <p className="text-gray-600 mb-6">Siempre vas a poder cambiar de cuerpo en tu perfil</p>
      {categories.map((category, index) => (
        <div
          key={index}
          className="flex items-center justify-start bg-white rounded-lg p-4 mb-4 shadow-lg w-4/5 cursor-pointer transition-shadow duration-200 hover:shadow-xl"
          style={{ backgroundColor: category.color }}
          onClick={() => handleCuerpoSelect(category.shortName)}
        >
          <div className="flex justify-center items-center w-12 h-12 mr-4">
            {category.icon}
          </div>
          <span className="text-lg font-medium text-gray-800">{category.name}</span>
        </div>
      ))}
    </div>
  );
};

export default CuerpoSelector;
