import io from "socket.io-client";

const SERVER_URL = "https://opocheckchat.onrender.com"; // Asegúrate de usar la URL de tu servidor
export const socket = io(SERVER_URL); // Exporta la instancia de socket

export const sendMessage = (message) => {
  socket.emit("askGPT", message);
};

export const onMessageReceived = (callback) => {
  socket.on("gptAnswer", callback);
};

export const onErrorReceived = (callback) => {
  socket.on("error", callback);
};