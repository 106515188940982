import React, { useEffect } from 'react';
import Swal from 'sweetalert2';

const NetworkStatusHandler = ({ children }) => {
  useEffect(() => {
    const handleOnline = () => {
      Swal.fire({
        icon: 'success',
        title: 'Conexión Restablecida',
        text: 'Tu conexión a internet ha sido restablecida.',
        timer: 2000,
      });
    };

    const handleOffline = () => {
      Swal.fire({
        icon: 'error',
        title: 'Sin Conexión a Internet',
        text: 'No tienes conexión a internet. Por favor, verifica tu conexión.',
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  return <>{children}</>;
};

export default NetworkStatusHandler;
