// retoService.js

import axios from 'axios';

// Asume que tienes una variable de entorno o una constante que guarda la URL base de tu API
const BASE_URL = 'https://opocheck.onrender.com/api/retos'; // Asegúrate de cambiar esto por tu URL real

const retoService = {
  // Crear un nuevo reto
  createReto: async (retoData) => {
    try {
      const response = await axios.post(`${BASE_URL}`, retoData);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al crear el reto';
    }
  },

  // Obtener todos los retos
  getAllRetos: async () => {
    try {
      const response = await axios.get(`${BASE_URL}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener los retos';
    }
  },

  // Obtener un reto por ID
  getRetoById: async (id) => {
    try {
      const response = await axios.get(`${BASE_URL}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener el reto por ID';
    }
  },

  // Actualizar un reto por ID
  updateRetoById: async (id, retoData) => {
    try {
      const response = await axios.put(`${BASE_URL}/${id}`, retoData);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al actualizar el reto';
    }
  },

  // Marcar un reto como completado
  markRetoAsCompleted: async (id) => {
    try {
      const response = await axios.patch(`${BASE_URL}/retos/${id}/completar`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al marcar el reto como completado';
    }
  },

  // Dentro de tu servicio de retos
markAsCanjeado: async (retoId) => {
    try {
      const response = await axios.patch(`${baseUrl}/retos/${retoId}/canje`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al marcar el reto como canjeado';
    }
  },
  

  // Eliminar un reto por ID
  deleteRetoById: async (id) => {
    try {
      await axios.delete(`${BASE_URL}/${id}`);
      return { message: 'Reto eliminado exitosamente' };
    } catch (error) {
      throw error.response.data.message || 'Error al eliminar el reto';
    }
  },

  // Obtener retos activos
  getRetosActivos: async () => {
    try {
      const response = await axios.get(`${BASE_URL}/activos`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener retos activos';
    }
  }
};

export default retoService;
