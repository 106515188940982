import React, { useState, useEffect, useRef } from 'react';
import TestInterface from './TestInterface'; // Asegúrate de que la ruta sea correcta
import ScoreCard from './Score'; // Asegúrate de que la ruta sea correcta y el nombre también
import ListTest from './ListTest';
import UserService from '../services/User'; // Asegúrate de que la ruta sea correcta
import { useGlobal } from '../context/GlobalContext';
import preguntasService from '../services/Pregunta';

const style = {
  alignItems: 'center',
  button: {
    marginTop: '30px',
    padding: '15px 30px',
    fontSize: '1em',
    backgroundColor: '#5cb85c',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
    transition: 'background-color 0.2s ease-in-out',
    '&:hover': {
      backgroundColor: '#449d44',
    },
    '&:disabled': {
      backgroundColor: '#ccc',
      cursor: 'not-allowed',
    },
  },
  floatingButton: {
    position: 'fixed',
    bottom: '20px',
    right: '20px',
    backgroundColor: '#007bff',
    color: 'white',
    padding: '10px 20px',
    borderRadius: '50%',
    boxShadow: '0px 4px 8px rgba(0,0,0,0.2)',
    cursor: 'pointer',
    zIndex: 1000,
  },
};

const TestWrapper = ({ preguntas, duracion, id, userId, categoryId }) => {
  const { state, dispatch } = useGlobal(); // Accede al estado global
  const [mode, setMode] = useState('test');
  const [respuestasUsuario, setRespuestasUsuario] = useState([]);
  const [results, setResults] = useState({
    correct: 0,
    incorrect: 0,
    unanswered: 0,
  });
  const [showListTest, setShowListTest] = useState(false);
  const [preguntasData, setPreguntasData] = useState([]);
  const scoreCardRef = useRef(null);

  useEffect(() => {
    const getPreguntas = async () => {
      try {
        const data = await Promise.all(
          preguntas.map(preguntaId => preguntasService.getPreguntaById(preguntaId))
        );
        setPreguntasData(data);
        //setRespuestas(Array(data.length).fill(null));
      } catch (error) {
        console.error('Error al obtener las preguntas:', error);
      }
    };

    if (preguntas.length > 0) {
      getPreguntas();
    }
  }, [preguntas]);

  const prepareTestData = (respuestasUsuario) => {
    console.log("preguntas", preguntasData);

    const separateOptions = (optionsArray) => {
      // Verifica si optionsArray es un array antes de proceder
      if (!Array.isArray(optionsArray)) {
        return ['Opciones no disponibles'];
      }
      // Devuelve el array tal como está
      return optionsArray;
    };

    return preguntasData.map((pregunta, index) => {
      const respuestaUsuario = respuestasUsuario[index];
      const respuestaCorrecta = pregunta.opcionCorrecta;
      const esCorrecta = respuestaUsuario === respuestaCorrecta ? 'Correcta' : 'Incorrecta';

      // Si pregunta.opciones es un array, lo manejamos correctamente
      const opcionesArray = separateOptions(pregunta.opciones);
      const opcionesFormateadas = opcionesArray.join('\n- '); // Une las opciones con un formato adecuado

      return `Pregunta: ${pregunta.pregunta}
  Opciones: 
  - ${opcionesFormateadas}
  Respuesta Seleccionada: ${respuestasUsuario[index]} (${esCorrecta})
  Respuesta Correcta: ${respuestaCorrecta}
  Tema: ${pregunta.tema}
  Texto de Referencia: ${pregunta.texto}\n\n`;
    }).join('');
  };


  const updateTestCompleted = async (scoreP, respuestas) => {
    if (userId) {
      const testData = prepareTestData(respuestas);
      try {
        await UserService.addOrUpdateTestCompleted(userId, id, scoreP, testData, categoryId);
        // Despacha una acción para actualizar el estado global del usuario
        dispatch({
          type: 'UPDATE_TEST_COMPLETED',
          payload: { testId: id, score: scoreP }
        });
      } catch (error) {
        console.error('Error al actualizar testCompleted:', error);
      }
    }
  };


  const handleTestSubmit = async (respuestas) => {
    const storedResponses = localStorage.getItem('respuestas_test_' + (preguntas[0] || id));
    const userResponses = storedResponses ? JSON.parse(storedResponses) : respuestas;

    let correct = 0;
    let incorrect = 0;
    let unanswered = userResponses.filter(respuesta => respuesta === null).length;

    userResponses.forEach((respuesta, index) => {
      if (respuesta === null) return;
      if (respuesta === preguntasData[index].opcionCorrecta) correct++;
      else incorrect++;
    });

    setResults({ correct, incorrect, unanswered });
    setRespuestasUsuario(userResponses);
    setMode('review');

    const score = Math.max(correct - Math.floor(incorrect / 4), 0);
    await updateTestCompleted(score, userResponses);
    localStorage.removeItem('respuestas_test_' + (preguntas[0] || id)); // Clean up localStorage after processing
  };


  if (showListTest) {
    return <ListTest />;
  }

  const handleViewScore = () => {
    if (scoreCardRef.current) {
      scoreCardRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div>
      {mode === 'test' ? (
        <TestInterface
          preguntas={preguntas}
          duracion={duracion}
          id={id}
          onSubmit={handleTestSubmit}
          mode={mode}
        />
      ) : (
        <div style={{ marginTop: "5px" }}>
          <div id="scoreCard" ref={scoreCardRef}>
            <ScoreCard
              correct={results.correct}
              incorrect={results.incorrect}
              unanswered={results.unanswered}
              score={Math.max(results.correct - Math.floor(results.incorrect / 4), 0)}
            />
          </div>
          <TestInterface
            preguntas={preguntas}
            duracion={duracion}
            id={id}
            mode="review"
            respuestasUsuario={respuestasUsuario}
          />
        {mode === 'review' && (
        <button
        style={{ width: '100%', padding: '0.75rem 1rem', backgroundColor: '#3B82F6', color: '#000000', border: 'none', borderRadius: '8px', cursor: 'pointer', fontSize: '15px', marginBottom: '20px' }}
        onClick={handleViewScore}
        >
          Ver Puntuación
        </button>
      )}
          <div>
            <button
              type="submit"
              style={{ width: '100%', padding: '0.75rem 1rem', backgroundColor: '#9CAFB7', color: '#000000', border: 'none', borderRadius: '8px', cursor: 'pointer', fontSize: '15px', marginBottom: '20px' }}
              onClick={() => setShowListTest(true)}
            >
              Volver al Listado
            </button>
          </div>
        </div>
      )}

    </div>
  );
};

export default TestWrapper;

