import React, { useState, useEffect } from 'react';
import { useGlobal } from '../context/GlobalContext';
import { useLocation } from 'react-router-dom';
import CheckoutService from '../services/checkout'; // Asegúrate de ajustar la importación según tu estructura de archivos
import SessionService from '../services/Session'; // Importa el servicio que gestiona las sesiones
import ConversationService from '../services/Conversation';

const OrderConfirmationPage = () => {
  const location = useLocation();
  const [verificationResponse, setVerificationResponse] = useState('');
  const { state } = useGlobal();
  const { user } = state;

  const verifySession = async () => {
    try {
      const sessionId = new URLSearchParams(location.search).get('session_id');
      console.log("Session ID:", sessionId);
      if (!sessionId) {
        setVerificationResponse('Información de la sesión no proporcionada');
        return;
      }
  
      // Primero verifica si la sesión ya ha sido utilizada
      const session = await SessionService.getSessionById(sessionId);
      if (session.used) {
        setVerificationResponse('Esta sesión ya ha sido utilizada.');
        return;
      }
  
      // Si la sesión no ha sido utilizada, verifica y procesa la sesión
      const response = await CheckoutService.verifyCheckoutSession(sessionId);
      console.log('Verificación exitosa:', response);
      setVerificationResponse('Pago confirmado');
  
      // Marcar la sesión como utilizada
      console.log("antes de marcar sesion como usada");
      await SessionService.markSessionAsUsed(session._id);
  
      // Obtener los datos actualizados del usuario desde el backend
      const updatedUser = await UserService.getUserById(user.id);
  
      // Actualizar el estado del usuario en el frontend
      dispatch({
        type: 'UPDATE_USER',
        payload: updatedUser
      });
  
      let consultasIncremento;
      switch (updatedUser.subscriptionCategory) {
        case 'basic':
          consultasIncremento = 30;
          break;
        case 'pro':
          consultasIncremento = 60;
          break;
        case 'vip':
          consultasIncremento = 1000;
          break;
        default:
          consultasIncremento = 0;
      }
  
      console.log("antes de agregar creditos");
      await ConversationService.addCreditosConsulta(updatedUser.id, consultasIncremento, updatedUser.subscriptionCategory);
      console.log("despues de agregar creditos");
    } catch (error) {
      console.error('Error al verificar la sesión:', error);
      setVerificationResponse('Error en el pago');
    }
  };
  
  useEffect(() => {
    verifySession();
  }, []);
  

  return (
    <section className="bg-gray-100 flex flex-col justify-start min-h-screen">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6 m-auto mt-0 lg:mt-8">
        <div className="flex items-center justify-between">
          <span className="text-green-500">
            <svg className="w-16 h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
          </span>
          <h3 className="text-xl font-semibold text-gray-800 ml-2">
            Pedido completado
          </h3>
        </div>
        <div className="mt-4">
          <p className="text-gray-600">
            Tu pedido se ha completado con éxito. Gracias por tu compra.
          </p>
          {verificationResponse && <p className="text-gray-600 mt-2">{verificationResponse}</p>}
        </div>
      </div>
    </section>
  );
  

}

export default OrderConfirmationPage;
