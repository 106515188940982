import React, { useState, useEffect, useRef } from 'react';
import { sendMessage, onMessageReceived, onErrorReceived, socket } from '../services/Socket';
import ConversationService from '../services/Conversation';
import { FiLock } from 'react-icons/fi';
import { useGlobal } from '../context/GlobalContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import ThinkingAnimation from './ThinkingAnimation';
import { useNavigate } from 'react-router-dom';

const MySwal = withReactContent(Swal);

const Chat = () => {
  const [message, setMessage] = useState('');
  const [conversation, setConversation] = useState([]);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const lastSentMessageRef = useRef('');
  const [consultant, setConsultant] = useState(null);
  const { state } = useGlobal();
  const { user, cuerpo } = state;
  const navigate = useNavigate()

  useEffect(() => {
    if (user && user.id) {
      const fetchConversationHistory = async () => {
        try {
          MySwal.fire({
            title: 'Cargando historial de chats...',
            html: (
              <div className="flex flex-col items-center justify-center">
                <Circles color="#9CAFB7" height={80} width={80} />
              </div>
            ),
            allowOutsideClick: false,
            allowEscapeKey: false,
            showConfirmButton: false,
          });

          const conversationHistory = await ConversationService.getConversationHistory(user.id);
          
          if (!conversationHistory) {
            MySwal.close();
            return;
          }

          setConsultant(conversationHistory.consultant);

          if (conversationHistory.answers.length === 0 && conversationHistory.responses.length === 0) {
            MySwal.close();
            return;
          }

          let combinedConversation = [];
          for (let i = 0; i < conversationHistory.answers.length; i++) {
            combinedConversation.push({ type: 'question', message: `Tú: \n ${conversationHistory.answers[i]}` });
            if (conversationHistory.responses[i]) {
              combinedConversation.push({ type: 'response', message: `Tutor: \n ${conversationHistory.responses[i]}` });
            }
          }
          setConversation(combinedConversation.slice(-6));

          setTimeout(() => {
            MySwal.close();
          });
        } catch (error) {
          console.error('Error al obtener el historial de conversaciones:', error);
          MySwal.fire('Error', 'Hubo un error al cargar el historial de chats. Por favor, intenta de nuevo.', 'error');
        }
      };

      fetchConversationHistory();
    }
  }, [user]);

  useEffect(() => {
    const messageHandler = async (answer) => {
      const responseEntry = { type: 'response', message: `Tutor: \n ${answer}` };
      setConversation(prev => [...prev, responseEntry].slice(-5));
      setIsWaitingForResponse(false);

      if (user && user.id && lastSentMessageRef.current) {
        try {
          await ConversationService.sendConversation(user.id, lastSentMessageRef.current, answer, user.subscriptionCategory, cuerpo.largeName);
        } catch (error) {
          console.error('Error al enviar la conversación:', error);
        }
      }
    };
 
    onMessageReceived(messageHandler);
    onErrorReceived((error) => {
      console.error('Error recibido del socket:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Oops...',
        text: 'Algo salió mal!',
      });
    });

    return () => {
      socket.off("gptAnswer", messageHandler);
    };
  }, [user]);

  const handleSubscription = () => {
    navigate('/CheckoutPage', {
      state: {
        precioRefPro: 19.99, 
        precioDctoPro: 14.99, 
        precioRefVip: 31.99, 
        precioDctoVip: 24.99, 
        precioIdVip: 'price_1PNICRBodWVXIE1Gf3zldJrN', 
        priceIdBasic: 'price_1POJrFBodWVXIE1G1NdeQ8Fk', 
        priceIdPro: 'price_1POJogBodWVXIE1GBmtLCRgZ', 
        
      }
    });
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      const cursorPosition = e.target.selectionStart;
      const textBeforeCursor = message.substring(0, cursorPosition);
      const textAfterCursor = message.substring(cursorPosition);
      setMessage(textBeforeCursor + '\n' + textAfterCursor);
      setTimeout(() => {
        e.target.selectionStart = cursorPosition + 1;
        e.target.selectionEnd = cursorPosition + 1;
      }, 0);
    }
  };

  useEffect(() => {
    setConversation([]);
  }, [cuerpo]);

  const handleSendMessage = async (e) => {
    e.preventDefault();

    if (!user || !user.activeSus) return;

    if (consultant === 0) {
      MySwal.fire({
        icon: 'info',
        title: 'Consulta Límite Alcanzada',
        text: 'Has usado las 150 consultas que te corresponden este mes',
      });
      return;
    }

    if (message.trim()) {
      const newEntry = { type: 'question', message: `Tú: \n ${message}` };
      setConversation(prev => [...prev, newEntry].slice(-5));
      setIsWaitingForResponse(true);

      const lastQuestionEntry = conversation.slice().reverse().find(entry => entry.type === 'question');
      const lastResponseEntry = conversation.slice().reverse().find(entry => entry.type === 'response');
      const lastQuestion = lastQuestionEntry ? lastQuestionEntry.message : "no hay registro anterior";
      const lastResponse = lastResponseEntry ? lastResponseEntry.message : "no hay registro anterior";
      const prompt = {
        previousQuestion: lastQuestion,
        previousAnswer: lastResponse,
        currentQuestion: message,
        cuerpo: cuerpo.largeName
      };

      lastSentMessageRef.current = message;
      sendMessage(prompt);
      setMessage('');
    }
  };

  const handleClearChat = () => {
    MySwal.fire({
      title: '¿Estás seguro de que quieres limpiar el chat?',
      text: "Esta acción no se puede deshacer.",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Sí, limpiar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        setConversation([]);
        MySwal.fire(
          '¡Limpio!',
          'El chat ha sido limpiado.',
          'success'
        );
      }
    });
  };

  return (
    <div>
      
      {user && user.activeSus ? (
        
        <div className="flex flex-col w-full mx-auto my-5 border border-gray-300 rounded-lg shadow-lg" style={{ marginTop: "10px", maxWidth: "950px" }}>
          <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">TutorIA</h2>
          <>
            <ul className="list-none p-4 overflow-y-auto h-80 bg-white">
              {conversation.map((entry, index) => (
                <li key={index} className="mb-3 p-2 border-b border-gray-200">
                  {entry.type === 'question' ? (
                    <p className="text-blue-600">{entry.message}</p>
                  ) : (
                    entry.message.split('\n').map((paragraph, idx) => (
                      <p key={idx} className="text-gray-700">{paragraph}</p>
                    ))
                  )}
                </li>
              ))}
              {isWaitingForResponse && (
                <li className="mb-3 p-2 border-b border-gray-200">
                  <ThinkingAnimation />
                </li>
              )}
            </ul>
            <form className="flex p-4 border-t border-gray-200 bg-white" onSubmit={handleSendMessage}>
              <textarea
                className="flex-1 p-2 border border-gray-300 rounded-lg resize-none focus:outline-none focus:ring-2 focus:ring-blue-500"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyDown={handleKeyDown}
                placeholder="Envía una pregunta a TutorIA"
                rows="2"
              />
              <button className="px-4 py-2 ml-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500" type="submit">
                Enviar
              </button>
            </form>
            <button
              className="px-4 py-2 m-4 bg-red-500 text-white rounded-lg hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
              onClick={handleClearChat}
            >
              Limpiar Chat
            </button>
          </>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center p-6 mt-20">
          <FiLock className="text-6xl mb-6" />
          <p className="text-xl text-center mb-6">Este contenido es exclusivo para suscriptores.</p>
          <button
            onClick={handleSubscription}
            className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg text-xl md:text-sm lg:text-base"
          >
            Ver Planes
          </button>
        </div>
      )}
    </div>
  );
};

export default Chat;
