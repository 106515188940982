// preguntasService.js

import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/preguntas'; // Reemplaza con la URL de tu backend
// const baseUrl = 'http://localhost:3001/api/preguntas/'; // Reemplaza con la URL de tu backend

const preguntasService = {
  createPregunta: async (preguntaData) => {
    try {
      const response = await axios.post(baseUrl, preguntaData);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al crear la pregunta';
    }
  },

  getAllPreguntas: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener todas las preguntas';
    }
  },

  getPreguntaById: async (preguntaId) => {
    try {
      const response = await axios.get(`${baseUrl}/${preguntaId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener la pregunta por ID';
    }
  },

  updatePregunta: async (preguntaId, updatedData) => {
    try {
      const response = await axios.put(`${baseUrl}/${preguntaId}`, updatedData);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar la pregunta';
    }
  },

  deletePregunta: async (preguntaId) => {
    try {
      await axios.delete(`${baseUrl}/${preguntaId}`);
    } catch (error) {
      throw error.response.data.error || 'Error al eliminar la pregunta';
    }
  },

  getAleatorias: async () => {
    try {
      const response = await axios.get(`${baseUrl}/aleatorias`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener preguntas aleatorias';
    }
  }
};

export default preguntasService;
