import React, {useState, useEffect} from 'react'; // Importa el servicio CheckoutService


const ProgressBar = ({ progress, total }) => {
    // Calculamos el porcentaje de la barra de progreso
    const percentage = Math.round((progress / total) * 100);
  
    return (
      <div className="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
        <div className={`bg-blue-600 h-2.5 rounded-full`} style={{ width: `${Math.min(percentage, 100)}%`, backgroundColor: '#9CAFB7' }}></div>
      </div>
    );
  };

  export default ProgressBar