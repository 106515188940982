import React, { useEffect, useState } from 'react';


const ScoreCard = ({ correct, incorrect, unanswered, score }) => {
  
  return (
    <div className="max-w-md mx-auto p-4 bg-white rounded-lg shadow">
      <div className="text-center py-2 border-b border-gray-200 mb-4">
        <span className="text-lg font-semibold text-gray-700">Puntuación Total:</span>
        <span className="text-xl font-bold ml-2" style={{ color: '#9CAFB7' }}>{score}</span>
      </div>
      <div className="grid grid-cols-3 gap-4 text-center">
        <div className="py-2">
          <span className="text-2xl font-bold text-green-600">{correct}</span>
          <span className="block text-sm text-gray-600">Acertadas</span>
        </div>
        <div className="py-2">
          <span className="text-2xl font-bold text-red-600">{incorrect}</span>
          <span className="block text-sm text-gray-600">Falladas</span>
        </div>
        <div className="py-2">
          <span className="text-2xl font-bold text-gray-600">{unanswered}</span>
          <span className="block text-sm text-gray-600">Sin contestar</span>
        </div>
      </div>
    </div>
  );

};

export default ScoreCard;