import React from 'react';
import { useNavigate } from 'react-router-dom';
import { FiArrowLeftCircle } from 'react-icons/fi';

const CancelPage = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/CheckoutPage');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 p-4">
      <div className="bg-white shadow-md rounded-lg p-8 max-w-lg text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">Checkout Cancelado</h1>
        <p className="text-gray-600 mb-6">¿Elegiste la suscripción incorrecta? Explora nuestras opciones y vuelve para completar el pago.</p>
        <button
          onClick={handleGoBack}
          className="flex items-center px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <FiArrowLeftCircle className="mr-2 text-xl" />
          Volver a suscripciones
        </button>
      </div>
    </div>
  );
};

export default CancelPage;
