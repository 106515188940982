import React, { useState, useEffect, useMemo } from 'react';
import { FiLock, FiDownload } from 'react-icons/fi';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import CategoryTestService from '../services/CategoryTest';
import TestWrapper from './TestWrapper';
import { useGlobal } from '../context/GlobalContext';
import testService from '../services/TestService';
import { useLocation, useNavigate } from 'react-router-dom';
import BackButton from './BackButton';

const MySwal = withReactContent(Swal);

const ListTest = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tests, setTests] = useState([]);
  const [error, setError] = useState('');
  const [testScores, setTestScores] = useState({});
  const [numberPreguntas, setNumberPreguntas] = useState({});
  const [pdfUrls, setPdfUrls] = useState({});
  const { state } = useGlobal();
  const { user } = state;
  const [selectedTest, setSelectedTest] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  const categoryId = location.state?.categoryId;

  useEffect(() => {
    const fetchTestData = async () => {
      if (user && categoryId) {
        MySwal.fire({
          title: 'Cargando tests...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        try {
          const categoryData = await CategoryTestService.getCategoryTestById(categoryId);
          setTests(categoryData.tests || []);

          if (user.testCompleted && Array.isArray(user.testCompleted) && user.testCompleted.length > 0) {
            const filteredCompletedTests = user.testCompleted.filter(tc => 
              categoryData.tests.some(test => test._id === tc.testId)
            );
            const scores = {};
            const numPreguntas = {};
            const urls = {};
            for (const { testId, score, pdfUrl } of filteredCompletedTests) {
              try {
                const testDetails = await testService.getTestById(testId);
                if (testDetails) {
                  scores[testId] = score;
                  urls[testId] = pdfUrl;
                  numPreguntas[testId] = testDetails.numberPreguntas;
                } else {
                  console.error(`Detalles del test no encontrados para el testId: ${testId}`);
                }
              } catch (error) {
                console.error(`Error al obtener detalles del test para testId: ${testId}`, error);
              }
            }
            setTestScores(scores);
            setNumberPreguntas(numPreguntas);
            setPdfUrls(urls);
          }
          setTimeout(() => {
            MySwal.close();
          });
        } catch (error) {
          console.error('Error al obtener los datos:', error);
          setError('Ocurrió un error al cargar los datos.');
          MySwal.fire('Error', 'Hubo un error al cargar los tests. Por favor, intenta de nuevo.', 'error');
        }
      }
    };

    fetchTestData();
  }, [user, categoryId]);

  const handleSubscription = () => {
    navigate('/CheckoutPage', {
      state: {
        precioRefPro: 19.99, 
        precioDctoPro: 14.99, 
        precioRefVip: 31.99, 
        precioDctoVip: 24.99, 
        precioIdVip: 'price_1PNICRBodWVXIE1Gf3zldJrN', 
        priceIdBasic: 'price_1POJrFBodWVXIE1G1NdeQ8Fk', 
        priceIdPro: 'price_1POJogBodWVXIE1GBmtLCRgZ', 
      }
    });
  };

  const getCardStyle = (testId) => {
    const score = testScores[testId];
    const numeroPreguntas = numberPreguntas[testId];

    if (score !== undefined && numeroPreguntas) {
      return score >= numeroPreguntas / 2 ? "bg-green-100" : "bg-red-100";
    }
    return "bg-white";
  };

  const handleSelectedTest = async (testId) => {
    MySwal.fire({
      title: 'Cargando test...',
      html: (
        <div className="flex flex-col items-center justify-center">
          <Circles color="#9CAFB7" height={80} width={80} />
        </div>
      ),
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    try {
      const selected = tests.find(test => test._id === testId);
      setSelectedTest(selected);
      setTimeout(() => {
        MySwal.close();
      }, 1000);
    } catch (error) {
      console.error('Error al seleccionar el test:', error);
      setError('Error al seleccionar el test.');
      MySwal.fire('Error', 'Hubo un error al cargar el test. Por favor, intenta de nuevo.', 'error');
    }
  };

  const filteredTests = useMemo(() => tests.filter(test => test.name.toLowerCase().includes(searchTerm.toLowerCase())), [tests, searchTerm]);

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (selectedTest && selectedTest.preguntas && selectedTest._id) {
    return <TestWrapper preguntas={selectedTest.preguntas} duracion={selectedTest.duracion} id={selectedTest._id} userId={user.id} categoryId={categoryId} />;
  }

  return (
    <div className="flex flex-col items-center w-full min-h-screen" style={{marginTop:"10px"}}>
      {user && user.activeSus ? (
        <>
          <div className="flex items-center justify-center w-full px-6 mb-6 relative">
             <div className="absolute left-0">
               <BackButton />
             </div>
             <h2 className="text-2xl font-bold text-gray-800 mx-auto">Mis Tests</h2>
          </div>
          <input
            className="w-4/5 h-10 mb-6 border border-gray-300 rounded-md px-4"
            type="text"
            placeholder="Buscar Test..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <ul className="w-full flex flex-col items-center">
            {filteredTests.map((test) => (
              <li
                key={test._id}
                className={`w-11/12 max-w-md shadow-lg rounded-lg overflow-hidden p-4 mb-6 transition duration-300 ease-in-out cursor-pointer ${getCardStyle(test._id)}`}
                onClick={() => handleSelectedTest(test._id)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">{test.name}</h3>
                    <p className="text-sm text-gray-600">
                      {testScores[test._id] !== undefined && testScores[test._id] !== null
                        ? `Última Puntuación: ${testScores[test._id]}/${numberPreguntas[test._id]}`
                        : "Sin Calificar"
                      }
                    </p>
                  </div>
                  {pdfUrls[test._id] && (
                    <a
                      href={pdfUrls[test._id]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FiDownload className="text-xl" />
                    </a>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center p-6">
          <FiLock className="text-6xl mb-6" />
          <p className="text-xl text-center mb-6">Este contenido es exclusivo para suscriptores.</p>
          <button
            onClick={handleSubscription}
            className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg text-xl md:text-sm lg:text-base"
          >
            Ver Planes
          </button>
        </div>
      )}
    </div>
  );
};

export default ListTest;
