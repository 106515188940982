import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/audios'; // Cambia esto por la URL correcta de tu backend

const AudioService = {
  addAudio: async (audioData, file) => {
    const formData = new FormData();
    formData.append('title', audioData.title);
    formData.append('cuerpo', audioData.cuerpo);
    formData.append('file', file);

    try {
      const response = await axios.post(baseUrl, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al agregar nuevo audio');
    }
  },

  getAllAudios: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener todos los audios');
    }
  },

  getAudioById: async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener audio por ID');
    }
  },

  updateAudio: async (id, audioData) => {
    try {
      const response = await axios.put(`${baseUrl}/${id}`, audioData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al actualizar audio');
    }
  },

  deleteAudio: async (id) => {
    try {
      await axios.delete(`${baseUrl}/${id}`);
      return true;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al eliminar audio');
    }
  },
};

export default AudioService;
