import React, { useState } from 'react';
import UserService from '../services/User';
import Cookies from 'js-cookie';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Reset from './RessePassword';
import Register from './Register';

const Login = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [showRegister, setShowRegister] = useState(false);
  const [countryCode, setCountryCode] = useState('');
  const [showLoginError, setShowLoginError] = useState(false);
  const [showLoad, setShowLoad] = useState(false);
  const [showReset, setShowReset] = useState(false);

  const notifyError = (message) => toast.error(message, { autoClose: 5000 });
  const notifySuccess = (message) => toast.success(message, { autoClose: 5000 });

  const handleLogin = async (event) => {
    event.preventDefault();
    if (!countryCode || !phoneNumber || !password) {
      notifyError('Por favor, completa todos los campos.');
      return;
    }

    try {
      setShowLoginError(false);
      setShowLoad(true);
      const login = await UserService.login(`${countryCode}${phoneNumber}`, password);
      const datosUsuario = {
        id: login.id,
        token: login.token,
      };
      const datosUsuarioJSON = JSON.stringify(datosUsuario);
      Cookies.set('usuario', datosUsuarioJSON);
      notifySuccess('Inicio de sesión exitoso.');
      setShowLoad(false);
      setTimeout(() => {
        window.location.reload();
      }, 1000); // Esperar un segundo antes de recargar
    } catch (error) {
      setShowLoad(false);
      notifyError('Usuario o contraseña incorrectos.');
      setShowLoginError(true);
    }
  };

  const handleToggleRegister = () => {
    setShowRegister(!showRegister);
    if (showRegister) {
      setPhoneNumber('');
      setPassword('');
    }
  };

  const handleResetPassword = () => {
    setShowReset(!showReset);
  };

  return (
    <>
      <ToastContainer />
      {!showRegister && !showReset ? (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
          <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
            <h2 className="mb-6 text-2xl font-semibold text-center text-gray-800">Iniciar sesión</h2>
            <form className="space-y-6" onSubmit={handleLogin}>
              <div className="flex items-center space-x-3">
                <select
                  required
                  value={countryCode}
                  name='countryCode'
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="w-1/3 h-12 px-3 border border-gray-300 rounded-md"
                >
                  <option value="">Seleccionar país</option>
                  <option value="+1">EE. UU. (+1)</option>
                  <option value="+44">Reino Unido (+44)</option>
                  <option value="+34">España (+34)</option>
                </select>
                <input
                  type='tel'
                  placeholder='Número de teléfono'
                  className="w-2/3 h-12 px-3 border border-gray-300 rounded-md"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <input
                className="w-full h-12 px-3 border border-gray-300 rounded-md"
                type="password"
                placeholder="Contraseña"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {showLoginError && (
                <p className="text-blue-600 cursor-pointer" onClick={handleResetPassword}>
                  ¿Olvidaste la contraseña? Recuperar
                </p>
              )}
              <button
                type="submit"
                className="w-full h-12 text-black bg-[#9CAFB7] rounded-md hover:bg-[#8fa1a8]"
              >
                {showLoad ? 'Cargando...' : 'Acceder'}
              </button>
            </form>
            {!showRegister && !showReset && (
              <p className="mt-4 text-center text-blue-600 cursor-pointer" onClick={handleToggleRegister}>
                ¿No tienes una cuenta? Regístrate
              </p>
            )}
          </div>
        </div>
      ) : (
        showReset ? <Reset /> : <Register />
      )}
    </>
  );
};

export default Login;
