// levelService.js

import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/levels'; // Reemplaza con la URL de tu backend

const levelService = {
  createLevel: async (levelData) => {
    try {
      const response = await axios.post(baseUrl, levelData);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al crear el nivel';
    }
  },

  getAllLevels: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener todos los niveles';
    }
  },

  getLevelById: async (levelId) => {
    try {
      const response = await axios.get(`${baseUrl}/${levelId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener el nivel por ID';
    }
  },

  updateLevel: async (levelId, updatedData) => {
    try {
      const response = await axios.put(`${baseUrl}/${levelId}`, updatedData);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al actualizar el nivel';
    }
  },

  deleteLevel: async (levelId) => {
    try {
      await axios.delete(`${baseUrl}/${levelId}`);
      // Puede ser necesario retornar algún dato en caso de éxito
    } catch (error) {
      throw error.response.data.message || 'Error al eliminar el nivel';
    }
  },

  getLevelByScore: async (score) => {
    try {
      const response = await axios.get(`${baseUrl}/score/${score}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al encontrar el nivel por puntuación';
    }
  }
};

export default levelService;
