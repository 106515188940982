// Roadmap.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProgressBar from './ProgressBar';
import { useGlobal } from '../context/GlobalContext';
import levelService from '../services/Level';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import UserService from '../services/User';
import testService from '../services/TestService';
import Retos from './Retos';
import Stats from './Stats';

const MySwal = withReactContent(Swal);

const Roadmap = () => {
  const { state, dispatch } = useGlobal();
  const { user } = state;
  const [niveles, setNiveles] = useState([]);
  const [nivelUsuario, setNivelUsuario] = useState(null);
  const navigate = useNavigate();
  const [testsAprobados, setTestsAprobados] = useState(0);
  const [puntuacionTotal, setPuntuacionTotal] = useState(0);

  useEffect(() => {
    const getLevelsAndLevelUser = async () => {
      try {
        MySwal.fire({
          title: 'Cargando roadmap...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });
  
        // Obtener todos los niveles
        const nivelesData = await levelService.getAllLevels();
        setNiveles(nivelesData);
  
        // Calcular la puntuación total del usuario
        const puntuacionTotal = user.practicosCompleted.length + user.testCompleted.filter(test => test.approved).length;
  
        // Obtener el nivel del usuario en función de la puntuación total
        const nivelData = await levelService.getLevelByScore(puntuacionTotal);
        setNivelUsuario(nivelData);
  
        // Obtener detalles de los tests completados
        const testsWithScores = await Promise.all(user.testCompleted.map(async (test) => {
          const testDetails = await testService.getTestById(test.testId);
          console.log("Fetched test details:", testDetails);
          return { ...test, numberPreguntas: testDetails.numberPreguntas };
        }));
  
        console.log("Tests with scores:", testsWithScores);
  
        // Filtrar los tests aprobados
        const approvedTests = testsWithScores.filter(test => test.score >= test.numberPreguntas / 2);
        setTestsAprobados(approvedTests.length);
  
      } catch (error) {
        console.error('Error fetching levels:', error);
        MySwal.fire('Error', 'Hubo un error al cargar los niveles. Por favor, intenta de nuevo.', 'error');
      } finally {
        setTimeout(() => {
          MySwal.close();
        }, 1500);
      }
    };
  
    if (user) {
      getLevelsAndLevelUser();
    }
  }, [user]);
  

  useEffect(() => {
    const obtenerNivelPorPuntuacion = async () => {
      const puntuacionTotal = testsAprobados + user.responses.length;
      setPuntuacionTotal(puntuacionTotal);
      try {
        const nivel = await levelService.getLevelByScore(puntuacionTotal);
        setNivelUsuario(nivel);
      } catch (error) {
        console.error('Error al obtener el nivel por puntuación:', error);
      }
    };

    obtenerNivelPorPuntuacion();
  }, [testsAprobados, user.practicosCompleted.length]);

  const handleMarcarCompletado = async (nivelId) => {
    try {
      await UserService.addLevelToUser(user.id, nivelId);
      Swal.fire('Nivel marcado como completado exitosamente', '', 'success');
    } catch (error) {
      console.error('Error al marcar el nivel como completado:', error);
      Swal.fire('No se pudo marcar el nivel como completado.', '', 'error');
    }
  };

  return (
    <div className="container mx-auto px-4 py-8">
          <Retos />
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 text-center">
        {niveles.map((nivel) => {
          const isCompleted = nivel.id <= (nivelUsuario ? nivelUsuario.id : 0);
          const canMarcarCompletado = puntuacionTotal >= nivel.maxScore;

          return (
            <div key={nivel.id} className={`rounded-lg mt-5 shadow-lg p-4 ${isCompleted ? 'bg-green-100' : 'bg-white'}`}>
              <h3 className="text-lg font-semibold text-gray-800 mb-4">{nivel.nombre}</h3>
              <div className="mb-2 w-full">
                <p className="text-gray-600 text-sm mb-1">Tests Aprobados: {testsAprobados}/{nivel.testAprobed}</p>
                <ProgressBar progress={testsAprobados} total={nivel.testAprobed} />
              </div>
              <div className="mb-2 w-full">
                <p className="text-gray-600 text-sm mb-1">Simulacros Realizados: {user.practicosCompleted.length}/{nivel.simulacros}</p>
                <ProgressBar progress={user.practicosCompleted.length} total={nivel.simulacros} />
              </div>
              <div className="mb-2 w-full">
                <p className="text-gray-600 text-sm mb-1">Puntuación: {user.practicosCompleted.length + testsAprobados}/{nivel.maxScore}</p>
                <ProgressBar progress={user.practicosCompleted.length + testsAprobados} total={nivel.maxScore} />
              </div>
              {canMarcarCompletado && !user.nivelesCompletados.includes(nivel.id) && (
                <button
                  onClick={() => handleMarcarCompletado(nivel.id)}
                  className="mt-4 bg-green-600 text-white rounded p-2 w-full"
                >
                  Marcar Completado
                </button>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Roadmap;

