import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/document'; // Reemplaza con la URL de tu backend

const DocumentService = {
  generateDocument: async (iaResponseId, name) => {
    try {
      // Incluye pdfFileName en el cuerpo de la solicitud
      const response = await axios.post(`${baseUrl}/generate-document`, {
        iaResponseId: iaResponseId,
        name: name  // Añade este campo
      });
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al generar el documento';
    }
  },
  getDocumentById: async (userId) => {
    try {
      const response = await axios.get(`${baseUrl}/${userId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener datos del usuario';
    }
  },

updateDocumentById: async (id, newData) => {
  try {
    const response = await axios.put(`${baseUrl}/${id}`, newData);
    return response.data;
  } catch (error) {
    throw error.response.data.error || 'Error al actualizar el documento';
  }
}

};

export default DocumentService;