import React, { useState } from 'react';
import UserService from '../services/User';
import Cookies from 'js-cookie';
import Verify from './Verify';
import Login from './Login';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Register = () => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userName, setUserName] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [showVerify, setShowVerify] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [passwordError, setPasswordError] = useState('');
  const [countryCode, setCountryCode] = useState('');

  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message, callback) => toast.success(message, { onClose: callback });

  const handleRegister = async (event) => {
    event.preventDefault();
    if (!phoneNumber || !email || !password || !repeatPassword || !userName) {
      notifyError('Por favor, completa todos los campos.');
      return;
    }
    if (password !== repeatPassword) {
      notifyError('Las contraseñas no coinciden.');
      return;
    }
    const regex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+}{":;\'?/><,.\\[\\]\\\\]).{8,}$');
    if (!regex.test(password)) {
      setPasswordError('La contraseña debe tener al menos una letra minúscula, una letra mayúscula, un número, un carácter especial y tener una longitud mínima de 8 caracteres.');
      return;
    }

    try {
      const register = await UserService.register(`${countryCode}${phoneNumber}`, password, repeatPassword, email, userName);
      console.log("Register:", register);
      console.log("Usuario registrado correctamente");
      const datosUsuario = {
        id: register.id,
      };
      const datosUsuarioJSON = JSON.stringify(datosUsuario);
      console.log("datosUsuarioJSON:", datosUsuarioJSON);
      Cookies.set('usuario', datosUsuarioJSON);
      notifySuccess('Registro exitoso. Verifique su cuenta.', () => setShowVerify(true));
    } catch (error) {
      notifyError(error.response?.data?.message || 'Error al registrar usuario.');
    }
  };

  const handleShowLogin = () => {
    setShowLogin(true);
  };

  return (
    <>
      <ToastContainer />
      {!showVerify && !showLogin ? (
        <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
          <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
            <h2 className="mb-6 text-2xl font-semibold text-center text-gray-800">Registro</h2>
            <form className="space-y-6" onSubmit={handleRegister}>
              <div className="flex items-center space-x-3">
                <select
                  value={countryCode}
                  onChange={(e) => setCountryCode(e.target.value)}
                  className="w-1/3 h-12 px-3 border border-gray-300 rounded-md"
                  required
                >
                  <option value="">Seleccionar país</option>
                  <option value="+1">EE. UU. (+1)</option>
                  <option value="+44">Reino Unido (+44)</option>
                  <option value="+34">España (+34)</option>
                </select>
                <input
                  type="tel"
                  placeholder="Número de teléfono"
                  className="w-2/3 h-12 px-3 border border-gray-300 rounded-md"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  required
                />
              </div>
              <input
                type="email"
                placeholder="Correo electrónico"
                className="w-full h-12 px-3 border border-gray-300 rounded-md"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <input
                type="text"
                placeholder="Tu nombre"
                className="w-full h-12 px-3 border border-gray-300 rounded-md"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
              <input
                type="password"
                placeholder="Contraseña"
                className="w-full h-12 px-3 border border-gray-300 rounded-md"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
              {passwordError && <p className="text-red-600">{passwordError}</p>}
              <input
                type="password"
                placeholder="Repetir contraseña"
                className="w-full h-12 px-3 border border-gray-300 rounded-md"
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                required
              />
              <button
                type="submit"
                className="w-full h-12 text-black bg-[#9CAFB7] rounded-md hover:bg-[#8fa1a8]"
              >
                Registrarse
              </button>
            </form>
            <p
              className="mt-4 text-center text-blue-600 cursor-pointer"
              onClick={handleShowLogin}
            >
              ¿Ya tienes una cuenta? Iniciar sesión
            </p>
          </div>
        </div>
      ) : showVerify ? (
        <Verify />
      ) : (
        <Login />
      )}
    </>
  );
};

export default Register;

