import React, { useEffect, useState } from 'react';
import Countdown from 'react-countdown';
import { useGlobal } from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';

const CountdownOfferMobile = ({ date }) => {
  const { state } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Verifica que la fecha es válida antes de calcular `subscriptionEndDate`
  const subscriptionEndDate = new Date(new Date(date).getTime() + (7 * 24 * 60 * 60 * 1000));
  console.log("subscriptionEndDate:", subscriptionEndDate);

  const handleSubscription = () => {
    navigate('/CheckoutPage', {
      state: {
        precioRefPro: 19.99,
        precioDctoPro: 14.99,
        precioRefVip: 31.99,
        precioDctoVip: 24.99,
        priceIdBasic: 'price_1POJrFBodWVXIE1G1NdeQ8Fk',
        priceIdPro: 'price_1POJogBodWVXIE1GBmtLCRgZ',
        priceIdVip: "price_1PNICRBodWVXIE1Gf3zldJrN",
      }
    });
  };

  const renderer = ({ days, hours, minutes, seconds }) => {
    return (
      <div className="flex items-center justify-between bg-gray-800 text-white fixed top-0 w-full z-20 text-xs md:text-sm lg:text-base py-4 px-8">
        <p className="flex-1 text-center md:text-left">Obtén un 25% Dcto.</p>
        <div className="flex items-center space-x-1">
          <div className="flex items-center space-x-1 text-xxs md:text-xs lg:text-sm">
            <span className="bg-gray-700 px-1 py-1 rounded">{String(days).padStart(2, '0')}</span>
            <span className="hidden md:inline text-xxs md:text-xs lg:text-sm">DAYS</span>
          </div>
          <div className="flex items-center space-x-1 text-xxs md:text-xs lg:text-sm">
            <span className="bg-gray-700 px-1 py-1 rounded">{String(hours).padStart(2, '0')}</span>
            <span className="hidden md:inline text-xxs md:text-xs lg:text-sm">HOURS</span>
          </div>
          <div className="flex items-center space-x-1 text-xxs md:text-xs lg:text-sm">
            <span className="bg-gray-700 px-1 py-1 rounded">{String(minutes).padStart(2, '0')}</span>
            <span className="hidden md:inline text-xxs md:text-xs lg:text-sm">MINS</span>
          </div>
          <div className="flex items-center space-x-1 text-xxs md:text-xs lg:text-sm">
            <span className="bg-gray-700 px-1 py-1 rounded">{String(seconds).padStart(2, '0')}</span>
            <span className="hidden md:inline text-xxs md:text-xs lg:text-sm">SECS</span>
          </div>
        </div>
        <button
          onClick={handleSubscription}
          className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg text-xs md:text-sm lg:text-base"
        >
          Suscribirme
        </button>
      </div>
    );
  };

  return <Countdown date={subscriptionEndDate} renderer={renderer} />;
};

export default CountdownOfferMobile;
