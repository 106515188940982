import React, { useEffect, useState } from 'react';
import { GlobalProvider, useGlobal } from './context/GlobalContext';
import Cookies from 'js-cookie';
import CuerpoSelector from './components/Cuerpos';
import Menu from './components/Menu';
import UserService from './services/User';
import CuerpoService from './services/Cuerpo';
import LandingPage from './components/LandingPage';
import ThinkingAnimation from './components/ThinkingAnimation';

const AppContent = () => {
  const { state, dispatch } = useGlobal();
  const [isReady, setIsReady] = useState(false);
  const [token, setToken] = useState(null);

  useEffect(() => {
    const initApp = async () => {
      const cookieUsuario = Cookies.get('usuario');
      if (!cookieUsuario) {
        setIsReady(true);
        return;
      }

      const datosUsuario = JSON.parse(cookieUsuario);
      setToken(datosUsuario.token); // Se establece el token desde los datos del usuario
      const fetchedUser = await UserService.getUserById(datosUsuario.id);

      if (fetchedUser) {
        dispatch({ type: 'SET_USER', payload: fetchedUser });
        if (fetchedUser.cuerpo) {
          // Asumiendo que tienes una función getCuerpoById en algún servicio que no está especificado
          const fetchedCuerpo = await CuerpoService.getCuerpoById(fetchedUser.cuerpo);
          dispatch({ type: 'SET_CUERPO', payload: fetchedCuerpo });
        }
      }

      setIsReady(true);
    };

    initApp();
  }, [dispatch]);

  if (!isReady) {
    return (
      <div style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        backgroundColor: '#f0f0f0',
      }}>
        <div style={{
          textAlign: 'center',
          fontSize: '20px'
        }}>
          Cargando<ThinkingAnimation />
        </div>
      </div>
    );
  }

  if (!state.user || !token) { // Aquí usamos el estado token para la verificación
    return <LandingPage />;
  } else if (!state.user.cuerpo) {
    return <CuerpoSelector />;
  } else {
    return <Menu />;
  }
};

const App = () => {
  return (
    <GlobalProvider>
      <div className="App">
        <AppContent />
      </div>
    </GlobalProvider>
  );
};

export default App;
