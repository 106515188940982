// testService.js

import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/tests'; // Reemplaza con la URL de tu backend

const testService = {
  createTest: async (testData) => {
    try {
      const response = await axios.post(baseUrl, testData);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al crear el test';
    }
  },

  getAllTests: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener todos los tests';
    }
  },

  // updateTestPuntuacion: async (testId, puntuacion) => {
  //   try {
  //     const response = await axios.put(`${baseUrl}/${testId}/puntuacion`, { puntuacion });
  //     return response.data;
  //   } catch (error) {
  //     throw error.response.data.error || 'Error al actualizar la puntuación del test';
  //   }
  // },
  

  getTestById: async (testId) => {
    try {
      const response = await axios.get(`${baseUrl}/${testId}`);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al obtener el test por ID';
    }
  },

  updateTest: async (testId, updatedData) => {
    try {
      const response = await axios.put(`${baseUrl}/${testId}`, updatedData);
      return response.data;
    } catch (error) {
      throw error.response.data.error || 'Error al actualizar el test';
    }
  },

  deleteTest: async (testId) => {
    try {
      await axios.delete(`${baseUrl}/${testId}`);
    } catch (error) {
      throw error.response.data.error || 'Error al eliminar el test';
    }
  }
};

export default testService;
