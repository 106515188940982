import React, { useState } from 'react';
import UserService from '../services/User';
import NewPassword from './NewPassword';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const MySwal = withReactContent(Swal);

const Reset = () => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [showNewPassword, setShowNewPassword] = useState(false);

  const handleReset = async () => {
    try {
      setLoading(true);
      setError('');
      setSuccessMessage('');

      const response = await UserService.resetPassword(email);

      setLoading(false);
      setSuccessMessage(response.message);
      setShowNewPassword(true);
    } catch (error) {
      setLoading(false);
      setError(error.message || 'Error al solicitar restablecimiento de contraseña');
    }
  };

  return (
    <>
      {!showNewPassword ? (
        <div className="flex flex-col items-center justify-center h-screen bg-gray-100">
          <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
            <h2 className="mb-6 text-2xl font-semibold text-center text-gray-800">Ingresa tu email</h2>
            <input
              className="w-full h-12 px-3 border border-gray-300 rounded-md"
              type="text"
              placeholder="tuemail@example.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <button
              className="w-full h-12 text-white bg-blue-500 rounded-md hover:bg-blue-600 mt-4"
              onClick={handleReset}
              disabled={loading || !email.trim()}
            >
              {loading ? 'Enviando...' : 'Recuperar'}
            </button>
            {error && <p className="text-red-500 mt-4">{error}</p>}
            {successMessage && <p className="text-green-500 mt-4">{successMessage}</p>}
          </div>
        </div>
      ) : (
        <NewPassword email={email} />
      )}
    </>
  );
};

export default Reset;
