import React, { useState, useEffect, useMemo } from 'react';
import { FiLock, FiDownload } from 'react-icons/fi';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import PracticoService from '../services/practicos';
import PracticosWrapper from './PracticosWrapper';
import { useGlobal } from '../context/GlobalContext';
import { useNavigate, useLocation } from 'react-router-dom';
import CateogryPracticoService from '../services/CategoryPractico';
import CuerpoService from '../services/Cuerpo';
import BackButton from './BackButton';


const MySwal = withReactContent(Swal);

const Practicos = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [practicos, setPracticos] = useState([]);
  const [error, setError] = useState('');
  const [pdfUrls, setPdfUrls] = useState({});
  const { state } = useGlobal();
  const { user } = state;
  const [selectedPractico, setSelectedPractico] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const categoryId = location.state?.categoryId;

  useEffect(() => {
    const fetchPracticoData = async () => {
      if (user && (user.cuerpo || categoryId)) {
        MySwal.fire({
          title: 'Cargando prácticos...',
          html: (
            <div className="flex flex-col items-center justify-center">
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        try {
          let practicosData = [];
          if (categoryId) {
            const categoryData = await CateogryPracticoService.getCategoryPracticoById(categoryId);
            practicosData = categoryData.practicos || [];
          } else if (user.cuerpo) {
            const cuerpoData = await CuerpoService.getCuerpoById(user.cuerpo);
            practicosData = cuerpoData.practico || [];
          }
          setPracticos(practicosData);

          if (user.practicosCompleted && Array.isArray(user.practicosCompleted) && user.practicosCompleted.length > 0) {
            const filteredCompletedPracticos = user.practicosCompleted.filter(pc =>
              practicosData.some(practico => practico.id === pc.practicoId)
            );
            const scores = {};
            const urls = {};
            for (const { practicoId, score, pdfUrl } of filteredCompletedPracticos) {
              try {
                const practicoDetails = await PracticoService.getPracticosById(practicoId);
                if (practicoDetails) {
                  scores[practicoId] = score;
                  urls[practicoId] = pdfUrl;
                } else {
                  console.error(`Detalles del práctico no encontrados para el practicoId: ${practicoId}`);
                }
              } catch (error) {
                console.error(`Error al obtener detalles del práctico para practicoId: ${practicoId}`, error);
              }
            }
            setPdfUrls(urls);
          }

          setTimeout(() => {
            MySwal.close();
          });
        } catch (error) {
          console.error('Error al obtener los datos:', error);
          setError('Ocurrió un error al cargar los datos.');
          MySwal.fire('Error', 'Hubo un error al cargar los prácticos. Por favor, intenta de nuevo.', 'error');
        }
      }
    };

    fetchPracticoData();
  }, [user, categoryId]);

  const handleSubscription = () => {
    navigate('/CheckoutPage', {
      state: {
        precioRefPro: 19.99,
        precioDctoPro: 14.99,
        precioRefVip: 31.99,
        precioDctoVip: 24.99,
        precioIdVip: 'price_1PNICRBodWVXIE1Gf3zldJrN',
        priceIdBasic: 'price_1POJrFBodWVXIE1G1NdeQ8Fk',
        priceIdPro: 'price_1POJogBodWVXIE1GBmtLCRgZ',
      },
    });
  };

  const getCardStyle = (practicoId) => {
    const isCompleted = user.practicosCompleted?.some(pc => pc.practicoId === practicoId);
    const isApproved = user.practicosAprobados?.some(pa => pa.practicoId === practicoId);
  
    if (isCompleted && isApproved) {
      return "bg-green-100";
    } else if (isCompleted) {
      return "bg-red-100";
    }
    return "bg-white";
  };

  const handleSelectedPractico = async (practicoId) => {
    if (user.credits <= 0) {
      MySwal.fire({
        title: 'Sin créditos disponibles',
        text: 'No tienes créditos disponibles para hacer prácticos. Por favor, adquiere más créditos en tu perfil de usuario.',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Ir a perfil',
        cancelButtonText: 'Cancelar',
      }).then((result) => {
        if (result.isConfirmed) {
          navigate('/user');
        }
      });
    } else {
      MySwal.fire({
        title: 'Cargando práctico...',
        html: (
          <div className="flex flex-col items-center justify-center">
            <Circles color="#9CAFB7" height={80} width={80} />
          </div>
        ),
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      });

      try {
        const selected = practicos.find(practico => practico.id === practicoId);
        setSelectedPractico(selected);
        setTimeout(() => {
          MySwal.close();
        }, 1000);
      } catch (error) {
        console.error('Error al seleccionar el práctico:', error);
        setError('Error al seleccionar el práctico.');
        MySwal.fire('Error', 'Hubo un error al cargar el práctico. Por favor, intenta de nuevo.', 'error');
      }
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredPracticos = useMemo(() => practicos.filter(practico => practico.title.toLowerCase().includes(searchTerm.toLowerCase())), [practicos, searchTerm]);

  if (error) {
    return <div className="text-red-500 text-center">{error}</div>;
  }

  if (selectedPractico) {
    return (
      <div className="w-full">
        <PracticosWrapper
          desarrollo={selectedPractico.desarrollo}
          multiple={selectedPractico.multiple}
          duracion={selectedPractico.duracion || 3600}
          id={selectedPractico.id}
          userId={user.id}
          enunciado={selectedPractico.enunciado}
        />
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center w-full min-h-screen" style={{ marginTop: "10px" }}>
      {user && user.activeSus ? (
        <>
          <div className="flex items-center justify-center w-full px-6 mb-6 relative">
             <div className="absolute left-0">
               <BackButton />
             </div>
             <h2 className="text-2xl font-bold text-gray-800 mx-auto">Mis Prácticos</h2>
          </div>
          <input
            className="w-4/5 h-10 mb-6 border border-gray-300 rounded-md px-4"
            type="text"
            placeholder="Buscar Práctico..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <ul className="w-full flex flex-col items-center">
            {filteredPracticos.map((practico) => (
              <li
                key={practico.id}
                className={`w-11/12 max-w-md shadow-lg rounded-lg overflow-hidden p-4 mb-6 transition duration-300 ease-in-out cursor-pointer ${getCardStyle(practico.id)}`}
                onClick={() => handleSelectedPractico(practico.id)}
              >
                <div className="flex justify-between items-center">
                  <div>
                    <h3 className="text-lg font-semibold text-gray-800">{practico.title}</h3>
                    {practico.date && <p className="text-sm text-gray-600">Fecha: {formatDate(practico.date)}</p>}
                  </div>
                  {pdfUrls[practico.id] && (
                    <a
                      href={pdfUrls[practico.id]}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-500 hover:text-blue-700"
                      onClick={(e) => e.stopPropagation()}
                    >
                      <FiDownload className="text-xl" />
                    </a>
                  )}
                </div>
              </li>
            ))}
          </ul>
        </>
      ) : (
        <div className="flex flex-col items-center justify-center p-6">
          <FiLock className="text-6xl mb-6" />
          <p className="text-xl text-center mb-6">Este contenido es exclusivo para suscriptores.</p>
          <button
            onClick={handleSubscription}
            className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg text-xl md:text-sm lg:text-base"
          >
            Ver Planes
          </button>
        </div>
      )}
    </div>
  );
};

export default Practicos;
