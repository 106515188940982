import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom'; // useNavigate en lugar de useHistory
import { useGlobal } from '../context/GlobalContext';
import UserService from '../services/User';

const ReferralConfirmationPage = () => {
  const { referralCode } = useParams();
  const navigate = useNavigate(); // useNavigate en lugar de useHistory
  const { state } = useGlobal();
  const { user } = state;
  const [confirmationResponse, setConfirmationResponse] = useState('');

  useEffect(() => {
    const confirmReferral = async () => {
      try {
        if (!referralCode) {
          setConfirmationResponse('Código de referido no proporcionado.');
          return;
        }

        if (!user || !user.id) {
          setConfirmationResponse('Usuario no identificado.');
          return;
        }

        const response = await UserService.confirmReferral(user.id, referralCode);
        console.log('Confirmación de referido exitosa:', response);
        setConfirmationResponse('Referido confirmado con éxito.');
        setTimeout(() => {
          navigate('/test')
          window.location.reload();
        }, 2000); // Navega a '/test' después de 2 segundos
      } catch (error) {
        console.error('Error al confirmar el referido:', error);
        setConfirmationResponse('Error al confirmar el referido.');
      }
    };

    if (user) {
      confirmReferral();
    }
  }, [user, referralCode, navigate]); // Asegúrate de incluir navigate en las dependencias

  return (
    <section className="bg-gray-100 flex flex-col justify-start min-h-screen">
      <div className="max-w-md w-full bg-white shadow-lg rounded-lg p-6 m-auto mt-0 lg:mt-8">
        <div className="flex items-center justify-between">
          <span className="text-green-500">
            <svg className="w-16 h-16" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
            </svg>
          </span>
          <h3 className="text-xl font-semibold text-gray-800 ml-2">
            Confirmación de Referido
          </h3>
        </div>
        <div className="mt-4">
          <p className="text-gray-600">
            Bienvenid@ a la familia de opocheck
          </p>
          {confirmationResponse && <p className="text-gray-600 mt-2">{confirmationResponse}</p>}
        </div>
      </div>
    </section>
  );
}

export default ReferralConfirmationPage;
