import React, { useEffect, useState } from 'react';
import UserService from '../services/User';
import Cookies from 'js-cookie';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import FacebookEventService from '../services/FbConverted';

const MySwal = withReactContent(Swal);

const Verify = () => {
  const [code, setCode] = useState('');
  const [userId, setUserId] = useState('');
  const [user, setUser] = useState(null);
  const [showSend, setShowSend] = useState(false);

  const notifySuccess = (message, callback) => toast.success(message, { onClose: callback });

  useEffect(() => {
    try {
      const cookieUsuario = Cookies.get('usuario');
      if (cookieUsuario) {
        const datosUsuario = JSON.parse(cookieUsuario);
        setUserId(datosUsuario.id);
        UserService.getUserById(datosUsuario.id).then(setUser);
      }
    } catch (error) {
      console.log("Error al obtener ID de cookies", error);
    }
  }, []);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!code) {
        e.preventDefault();
        e.returnValue = '';
        MySwal.fire({
          title: 'Atención',
          text: 'Primero debes introducir el código de verificación. Si no lo has recibido, solicita uno nuevo.',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Solicitar nuevo código',
          cancelButtonText: 'Cancelar',
        }).then((result) => {
          if (result.isConfirmed) {
            handleResendCode();
          }
        });
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [code]);

  const handleVerify = async (event) => {
    event.preventDefault();
    try {
      if (!code) {
        MySwal.fire({
          title: 'Error',
          text: 'Por favor, ingresa el código.',
          icon: 'error',
        });
        return;
      }

      if (!user) {
        console.log("Usuario no encontrado");
        return;
      }

      if (code === user.verificationCode) {
        const verify = await UserService.confirmRegistration(code);
        const newDatosUsuario = {
          id: userId,
          token: verify.token,
        };
        const datosUsuarioJSON = JSON.stringify(newDatosUsuario);
        Cookies.set('usuario', datosUsuarioJSON);
        setShowSend(!showSend);
        console.log('Usuario confirmado');

        // Enviar evento de registro a Facebook
        const eventData = {
          email: user.email,
          customData: {
            // Otros datos personalizados del evento de registro
            date: new Date(), 
          },
          eventSourceUrl: window.location.href
        };

        try {
          await FacebookEventService.sendRegistrationEvent(eventData);
          console.log('Evento de registro enviado con éxito a Facebook');
        } catch (error) {
          console.error('Error al enviar evento de registro a Facebook:', error);
        }

        notifySuccess('Usuario verificado con éxito.', () => {
          window.location.reload();
        });
      } else {
        MySwal.fire({
          title: 'Error',
          text: 'Código incorrecto, inténtalo de nuevo.',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error al verificar el código:', error);
      MySwal.fire({
        title: 'Error',
        text: 'Hubo un error al verificar el código. Por favor, intenta de nuevo.',
        icon: 'error',
      });
    }
  };

  const handleResendCode = async () => {
    try {
      const getUser = await UserService.getUserById(userId);
      setUser(getUser);

      if (getUser) {
        await UserService.resendSMSCode(getUser.phoneNumber, getUser.verificationCode);
        MySwal.fire({
          title: 'Éxito',
          text: 'Código de verificación reenviado correctamente.',
          icon: 'success',
        });
      } else {
        console.log('No se encontró el usuario para reenviar el código.');
      }
    } catch (error) {
      MySwal.fire({
        title: 'Error',
        text: 'Error al reenviar el código de verificación.',
        icon: 'error',
      });
      console.error('Error al reenviar el código de verificación:', error);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <ToastContainer />
      <div className="w-full max-w-md p-8 bg-white rounded-lg shadow-md">
        <h2 className="mb-6 text-2xl font-semibold text-center text-gray-800">Verificar Código</h2>
        <form className="space-y-6" onSubmit={handleVerify}>
          <input
            className="w-full h-12 px-3 border border-gray-300 rounded-md mb-4"
            type="tel"
            placeholder="Código de verificación"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            required
          />
          <p className="text-blue-500 cursor-pointer underline mb-4" onClick={handleResendCode}>
            Reenviar código
          </p>
          <button
            type="submit"
            className="w-full h-12 bg-[#9CAFB7] text-black text-lg font-bold rounded-md hover:bg-[#8fa1a8]"
          >
            Verificar
          </button>
        </form>
      </div>
    </div>
  );
};

export default Verify;
