import React, { useState } from 'react';
import { useGlobal } from '../context/GlobalContext';
import { useNavigate } from 'react-router-dom';
import noticiaService from '../services/Noticia';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { FiArrowLeft } from 'react-icons/fi';

const MySwal = withReactContent(Swal);

const CreateNoticia = () => {
  const [url, setUrl] = useState('');
  const [text, setText] = useState('');
  const [title, setTitle] = useState('');
  const { state } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!user || !user.id) {
      MySwal.fire({
        icon: 'error',
        title: 'Usuario no identificado',
        text: 'Por favor, inicie sesión para crear una noticia.',
      });
      return;
    }
    try {
      const noticiaData = {
        title,
        url,
        text,
        user: user.id,
      };
      await noticiaService.createNoticia(noticiaData);
      setUrl('');
      setText('');
      setTitle('');
      MySwal.fire({
        icon: 'success',
        title: 'Noticia creada',
        text: 'La noticia se ha creado exitosamente.',
      }).then(() => {
        setTimeout(() => {
          navigate('/noticias');
        }, 3000); // Esperar 3 segundos antes de navegar
      });
    } catch (error) {
      console.error('Hubo un error al crear la noticia:', error);
      MySwal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Hubo un error al crear la noticia. Por favor, inténtelo de nuevo.',
      });
    }
  };

  return (
    <div className="container mx-auto mt-10 p-6 bg-white rounded-2xl shadow-lg max-w-2xl" style={{ marginTop: "10px" }}>
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-3xl font-bold text-gray-800">Crear Noticia</h2>
        <button
          onClick={() => navigate('/noticias')}
          className="flex items-center px-4 py-2 bg-gray-200 text-gray-700 rounded-full shadow hover:bg-gray-300 transition duration-200"
        >
          <FiArrowLeft className="mr-2" />
          Volver
        </button>
      </div>
      <form onSubmit={handleSubmit} className="space-y-6">
        <div>
          <label htmlFor="title" className="block text-gray-700 font-semibold mb-2">Título de la Noticia:</label>
          <input
            type="text"
            id="title"
            name="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-200 focus:border-blue-300 transition duration-200"
            placeholder="Ingrese el título de la noticia"
          />
        </div>
        <div>
          <label htmlFor="url" className="block text-gray-700 font-semibold mb-2">URL de la Noticia:</label>
          <input
            type="text"
            id="url"
            name="url"
            value={url}
            onChange={(e) => setUrl(e.target.value)}
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-200 focus:border-blue-300 transition duration-200"
            placeholder="Ingrese la URL de la noticia"
          />
        </div>
        <div>
          <label htmlFor="text" className="block text-gray-700 font-semibold mb-2">Texto de la Noticia:</label>
          <textarea
            id="text"
            name="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            rows="4"
            maxLength="350"
            className="w-full p-4 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-200 focus:border-blue-300 transition duration-200"
            placeholder="Ingrese el contenido de la noticia"
          />
        </div>
        <button
          type="submit"
          className="w-full py-3 bg-[#9CAFB7] text-black font-bold rounded-full shadow-lg hover:opacity-90 transition duration-200"
        >
          Publicar Noticia
        </button>
      </form>
    </div>
  );
};

export default CreateNoticia;
