import React, { useState } from 'react';
import Login from './Login';
import Register from './Register';

const LandingPage = () => {
  const [view, setView] = useState('landing');

  const handleViewChange = (newView) => setView(newView);

  return view === 'landing' ? (
    <div className="flex flex-col min-h-screen bg-gradient-to-br from-gray-800 to-black text-white">
      <nav className="flex justify-between items-center px-5 py-4 sm:px-10 sm:py-6">
        <div className="text-2xl sm:text-3xl font-bold text-primary hover:text-primary-light cursor-pointer">
          Opocheck
        </div>
        <div>
          <button
            onClick={() => handleViewChange('login')}
            className="bg-transparent text-primary border border-primary rounded-full px-4 py-2 sm:px-6 sm:py-2 mr-4 hover:bg-primary hover:text-black transition-colors duration-300"
          >
            Iniciar Sesión
          </button>
        </div>
      </nav>
      <div className="flex-grow flex flex-col items-center justify-center px-4 sm:px-6 max-w-3xl mx-auto">
        <h2 className="text-3xl sm:text-4xl md:text-5xl font-bold mb-4 sm:mb-6 text-center">
          Obtén tu plaza como Agente de Hacienda en tiempo record
        </h2>
        <div className="mb-6 sm:mb-8 text-sm sm:text-lg font-light max-w-md sm:max-w-xl md:max-w-2xl text-center">
          Se parte de la Revolución con el primer corrector profesional impulsado por inteligencia artificial para aspirantes a Agente de Hacienda.
          <ul className="list-disc list-inside mt-4 text-left mx-auto max-w-sm">
            <li>Potente TutorIA disponible 24/7.</li>
            <li>CorrectorIA especializado en prácticos de desarrollo siguiendo los criterios oficiales de evaluación </li>
            <li>Nuevos Tests y Simulacros de Tests todas las semanas basados en los últimos examenes oficiales</li>
            <li>Nuevos Prácticos cada semana de selección multiple y desarrollo </li>
            <li>Temario completo disponible en formato digital y con opción de resaltado</li>
            <li>Cuaderno de apuntes disponible en cada tema</li>
            <li>Sin datos bancarios al principio, prueba y luego decide.</li>
            <li>Sin permanencia, sin sorpresas.</li>
          </ul>
        </div>
        <button
          onClick={() => handleViewChange('register')}
          className="bg-[#9CAFB7] text-black font-bold uppercase px-6 sm:px-8 py-2 sm:py-3 rounded-full shadow-lg hover:bg-[#7C9CA7] transition-colors duration-300"
        >
          Comienza gratis
        </button>
      </div>
      <footer className="text-center text-primary py-2 sm:py-4">
        &copy; {new Date().getFullYear()} Opocheck. Avanzando juntos. Todos los derechos reservados.
      </footer>
    </div>
  ) : view === 'login' ? (
    <Login />
  ) : (
    <Register />
  );
};

export default LandingPage;
