import React, { useState, useEffect } from 'react';
import { FiEdit, FiSave, FiMinus, FiPlus, FiCopy } from 'react-icons/fi';
import Cookies from 'js-cookie';
import UserService from '../services/User';
import CheckoutService from '../services/checkout';
import { useGlobal } from '../context/GlobalContext';
import testService from '../services/TestService';
import ProgressBar from './ProgressBar';
import levelService from '../services/Level';
import ConversationService from '../services/Conversation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useNavigate } from 'react-router-dom';
import Stats from './Stats';

const MySwal = withReactContent(Swal);

const User = () => {
  const navigate = useNavigate();
  const { state, dispatch } = useGlobal();
  const { user, cuerpo } = state;
  const [testsAprobados, setTestsAprobados] = useState(0);
  const [localUser, setLocalUser] = useState(user);
  const [editMode, setEditMode] = useState({
    email: false,
    cuerpo: false,
    userName: false
  });
  const [creditAmount, setCreditAmount] = useState(1);
  const [consultant, setConsultant] = useState(10);
  const [renewalSubscriptionDate, setRenewalSubscriptionDate] = useState(new Date());
  const [nivelUsuario, setNivelUsuario] = useState(0);
  const [consultas, setConsultas] = useState(0);

  const cuerpoOptions = [
    { id: cuerpo._id, largeName: cuerpo.largeName },
    { id: "6604b39354b2a5da935209a6", largeName: "Agente de Hacienda (Acceso libre y Promoción Interna)" },
    { id: "660aaf8a07185ba269e0486c", largeName: "Técnico de Hacienda" }
  ];

  const notifyError = (message) => toast.error(message);
  const notifySuccess = (message) => toast.success(message);

  useEffect(() => {
    setLocalUser(user);
  }, [user]);

  useEffect(() => {
    const fetchConsultas = async () => {
      if (user) {
        try {
          const consultas = await ConversationService.getConversationHistory(user.id);
          setConsultas(consultas.consultant);
        } catch (error) {
          console.error("Error obteniendo el historial de conversaciones:", error);
        }
      } else {
        console.log("Usuario no disponible para actualizar la fecha");
      }
    };

    fetchConsultas();
  }, [user]);

  useEffect(() => {
    const calculateRenewalDate = () => {
      if (user.renewalSubscriptionDate) {
        return new Date(user.renewalSubscriptionDate);
      } else if (!user.activeSus && user.endTrialDate && !user.cancellationDate) {
        return new Date(user.endTrialDate);
      } else {
        return user.hasUsedFreeTrial && !user.endTrialDate
          ? new Date(new Date(user.subscriptionStartDate).getTime() + (7 * 24 * 60 * 60 * 1000))
          : new Date(new Date(user.subscriptionStartDate).getTime() + (30 * 24 * 60 * 60 * 1000));
      }
    };

    if (user) {
      const date = calculateRenewalDate();
      setRenewalSubscriptionDate(date);
    } else {
      console.log("Usuario no disponible para actualizar la fecha");
    }
  }, [user]);

  useEffect(() => {
    const fetchTestsAndCalculateApproved = async () => {
      try {
        console.log("Fetching test details for:", user.testCompleted);
        const testsWithScores = await Promise.all(user.testCompleted.map(async (test) => {
          const testDetails = await testService.getTestById(test.testId);
          console.log("Fetched test details:", testDetails);
          return { ...test, numberPreguntas: testDetails.numberPreguntas };
        }));

        console.log("Tests with scores:", testsWithScores);
        const approvedTests = testsWithScores.filter(test => test.score >= test.numberPreguntas / 2);
        setTestsAprobados(approvedTests.length);
        console.log("Approved tests:", approvedTests);
      } catch (error) {
        console.error('Error fetching test details or calculating approved tests:', error);
      }
    };

    if (user.testCompleted && user.testCompleted.length > 0) {
      fetchTestsAndCalculateApproved();
    } else {
      console.log("No completed tests found for the user.");
    }
  }, [user.testCompleted]);

  useEffect(() => {
    const obtenerNivelPorPuntuacion = async () => {
      const puntuacionTotal = testsAprobados + user.practicosCompleted.length;
      try {
        const nivel = await levelService.getLevelByScore(puntuacionTotal);
        setNivelUsuario(nivel);
      } catch (error) {
        console.error('Error al obtener el nivel por puntuación:', error);
      }
    };

    obtenerNivelPorPuntuacion();
  }, [testsAprobados, user.practicosCompleted.length]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocalUser((prev) => ({ ...prev, [name]: value }));
  };

  const toggleEditMode = (field) => {
    setEditMode((prevEditMode) => ({ ...prevEditMode, [field]: !prevEditMode[field] }));
  };

  const handleSaveChanges = async (field, value) => {
    try {
      let updatedField;
      if (field === 'email') {
        updatedField = await UserService.updateEmail(user.id, localUser.email);
      } else if (field === 'cuerpo') {
        updatedField = await UserService.updateCuerpo(user.id, localUser.cuerpo);
      } else if (field === 'userName') {
        updatedField = await UserService.updateUserName(user.id, localUser.userName);
      }

      setLocalUser((prev) => ({ ...prev, ...updatedField }));
      dispatch({ type: 'UPDATE_USER_FIELD', payload: { field, value } });
      setEditMode(prev => ({ ...prev, [field]: false }));

      notifySuccess('Información actualizada con éxito.');
      window.location.reload();
    } catch (error) {
      notifyError(`Error al actualizar la información: ${error.message || 'Error desconocido'}`);
    }
  };

  const handleCancelSubscription = async (event) => {
    event.preventDefault();
    try {
      if (!user) {
        notifyError("Usuario no disponible");
        return;
      }
      const result = await MySwal.fire({
        title: '¿Estás seguro/a?',
        text: "¿Quieres cancelar tu suscripción?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#d33',
        cancelButtonColor: '#3085d6',
        confirmButtonText: 'Sí, cancelar',
        cancelButtonText: 'No, mantener'
      });
      if (result.isConfirmed) {
        const response = await CheckoutService.cancelSubscription(user.id);
        MySwal.fire('Cancelación realizada', response.message, 'success');
        setTimeout(() => {
          window.location.reload();
        }, 2000); // Opcionalmente, espera un poco antes de recargar la página
      }
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error al cancelar la suscripción.';
      notifyError(errorMessage);
    }
  };
  
  const handleContinueSubscription = async () => {
    try {
      const response = await CheckoutService.continueSubscription(user.id);
      MySwal.fire('Suscripción continuada', response.message, 'success');
      setTimeout(() => {
        window.location.reload();
      }, 2000); // Opcionalmente, espera un poco antes de recargar la página
    } catch (error) {
      const errorMessage = error.response?.data?.message || 'Error al continuar la suscripción.';
      notifyError(errorMessage);
    }
  };

  const handleSubscription = async (event) => {
    event.preventDefault();
    try {
      if (!user) {
        notifyError("Usuario no disponible");
        return;
      }
      navigate('/CheckoutPage', {
        state: {
          precioRefPro: 19.99, 
          precioDctoPro: 14.99, 
          precioRefVip: 31.99, 
          precioDctoVip: 24.99, 
          priceIdVip: 'price_1PNICRBodWVXIE1Gf3zldJrN', 
          priceIdBasic: 'price_1POJrFBodWVXIE1G1NdeQ8Fk', 
          priceIdPro: 'price_1POJogBodWVXIE1GBmtLCRgZ', 
          cuponIdBasicToPro: "NVS7T6dB",
          cuponIdBasicToVip: "NVS7T6dB",
          cuponIdProToVip: "2ZtVpnBY",
          cuponVipToPro: "2ZtVpnBY",
          cuponVipToBasic: "NVS7T6dB",
          cuponProToBasic: "NVS7T6dB",
          
        }
      });
    } catch (error) {
      notifyError('Error al suscribirse.');
    }
  };

  const handleIncreaseCredit = () => setCreditAmount((prevAmount) => prevAmount + 1);
  const handleDecreaseCredit = () => setCreditAmount((prevAmount) => (prevAmount > 1 ? prevAmount - 1 : 1));
  const handleIncreaseConsultas = () => setConsultant((prev) => prev + 10);
  const handleDecreaseConsultas = () => setConsultant((prev) => (prev > 10 ? prev - 10 : 10));

  const handleCompraCredits = async (event) => {
    event.preventDefault();
    try {
      if (!user) {
        notifyError("Usuario no disponible");
        return;
      }
      const session = await CheckoutService.createCheckoutSession(user.id, creditAmount);
      window.location.href = session.url;
    } catch (error) {
      notifyError('Error al comprar créditos.');
    }
  };

  const handleCompraConsultas = async (event) => {
    event.preventDefault();
    try {
      if (!user) {
        notifyError("Usuario no disponible");
        return;
      }
      const session = await CheckoutService.createSimplePaymentSession(consultant, user.id);
      window.location.href = session.url;
    } catch (error) {
      notifyError('Error al comprar consultas.');
    }
  };

  const handleLogout = () => {
    Cookies.remove('usuario');
    dispatch({ type: 'LOGOUT' });
    window.location.reload();
  };

  const subscriptionStatus = () => {
    if (user.activeSus && !user.hasUsedFreeTrial) {
      return `En periodo de prueba. Finaliza el: ${renewalSubscriptionDate.toLocaleDateString()}`;
    } else if (!user.activeSus && user.hasUsedFreeTrial) {
      return `Tu prueba gratuita finalizo el: ${renewalSubscriptionDate.toLocaleDateString()}`;
    } else if (user.subscriptionId && user.pendingCancellation) {
      return `Has cancelado tu suscripción: Tienes acceso hasta el: ${renewalSubscriptionDate.toLocaleDateString()}`;
    } else {
      return `Suscripción activa. Se renueva el: ${renewalSubscriptionDate.toLocaleDateString()}`;
    }
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => notifySuccess('Texto copiado al portapapeles exitosamente!'),
      (err) => console.error('Error al copiar texto al portapapeles:', err)
    );
  };

  return (
    <div className="container mx-auto px-4 mt-3.5 w-full md:w-auto" style={{ marginTop: "20px" }}>
      <ToastContainer />
      <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">Perfil de Usuario</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <Stats user={user} testsAprobados={testsAprobados}/>
        <div className="bg-gray-50 rounded-lg shadow-lg p-4">
          <div className="flex items-center justify-between">
            <span className="text-gray-700">Referir a un amigo:</span>
            <div className="flex items-center flex-grow ml-2">
              <input
                type="text"
                readOnly
                value={`https://opocheck.com/referral/${user.referralCode}`}
                className="flex-grow input input-bordered"
              />
              <button onClick={() => copyToClipboard(`https://opocheck.com/referral/${user.referralCode}`)} className="ml-5">
                <FiCopy size={20} />
              </button>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 rounded-lg shadow-lg p-4">
          <div className="flex items-center justify-between">
            <span className="text-gray-700">Mi Sucripción: <strong>{user.subscriptionCategory}</strong></span>
            <div className="flex items-center flex-grow ml-2">
              <button 
                className="ml-2 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-lg text-xl md:text-sm lg:text-base"
                onClick={handleSubscription}>
                  Ver Planes
              </button>
            </div>
          </div>
        </div>
        <div className="bg-gray-50 rounded-lg shadow-lg p-4">
          <div className="mb-4">
            {editMode.email ? (
              <>
                <input
                  type="email"
                  value={localUser.email}
                  onChange={(e) => handleInputChange(e)}
                  className="input input-bordered w-full mb-3"
                />
                <button className="btn btn-primary" onClick={() => handleSaveChanges('email')}>
                  Guardar
                </button>
              </>
            ) : (
              <div className="flex justify-between items-center">
                <span>{localUser.email}</span>
                <button onClick={() => toggleEditMode('email')}>
                  <FiEdit size={20} />
                </button>
              </div>
            )}
          </div>
          <div className="mb-4">
            {editMode.userName ? (
              <>
                <input
                  name="userName"
                  type="text"
                  value={localUser.userName}
                  onChange={(e) => handleInputChange(e)}
                  className="input input-bordered w-full mb-3"
                />
                <button className="btn btn-primary" onClick={() => handleSaveChanges('userName')}>
                  Guardar
                </button>
              </>
            ) : (
              <div className="flex justify-between items-center">
                <span>{localUser.userName}</span>
                <button onClick={() => toggleEditMode('userName')}>
                  <FiEdit size={20} />
                </button>
              </div>
            )}
          </div>
          <div className="mb-4">
            {editMode.cuerpo ? (
              <>
                <select
                  name="cuerpo"
                  value={localUser.cuerpo || ''}
                  onChange={(e) => handleInputChange(e)}
                  className="select select-bordered w-full mb-3"
                >
                  {cuerpoOptions.map((option) => (
                    <option key={option.id} value={option.id}>{option.largeName}</option>
                  ))}
                </select>
                <button className="btn btn-primary" onClick={() => handleSaveChanges('cuerpo')}>
                  Guardar
                </button>
              </>
            ) : (
              <div className="flex justify-between items-center">
                <span>{cuerpo.largeName}</span>
                <button onClick={() => toggleEditMode('cuerpo')}>
                  <FiEdit size={20} />
                </button>
              </div>
            )}
          </div>
        </div>
        <div className="bg-gray-50 rounded-lg shadow-lg p-4">
          <div className="flex justify-between items-center mb-3">
            <span className="text-gray-700">Mis consultas: {consultas || 0}</span>
            <div className="flex items-center">
              <button className="text-red-500 hover:bg-red-50 p-2 rounded-full" onClick={handleDecreaseConsultas}>
                <FiMinus size={20} />
              </button>
              <span className="mx-3">{consultant}</span>
              <button className="text-green-500 hover:bg-green-50 p-2 rounded-full" onClick={handleIncreaseConsultas}>
                <FiPlus size={20} />
              </button>
            </div>
          </div>
          <button className="w-full text-white bg-[#9CAFB7] hover:bg-[#8fa1a8] py-2 rounded-lg shadow" onClick={handleCompraConsultas}>
            Comprar
          </button>
          <div className="flex justify-between items-center mb-3">
            <span className="text-gray-700">Mis correcciones: {user.credits || 0}</span>
            <div className="flex items-center">
              <button className="text-red-500 hover:bg-red-50 p-2 rounded-full" onClick={handleDecreaseCredit}>
                <FiMinus size={20} />
              </button>
              <span className="mx-3">{creditAmount}</span>
              <button className="text-green-500 hover:bg-green-50 p-2 rounded-full" onClick={handleIncreaseCredit}>
                <FiPlus size={20} />
              </button>
            </div>
          </div>
          <button className="w-full text-white bg-[#9CAFB7] hover:bg-[#8fa1a8] py-2 rounded-lg shadow" onClick={handleCompraCredits}>
            Comprar
          </button>
          <p className="text-gray-700 mb-3 mt-4">{subscriptionStatus()}</p>
          {user.pendingCancellation ? (
            <button className="w-full py-2 rounded-lg shadow bg-green-500 hover:bg-green-600" onClick={handleContinueSubscription}>
              Continuar Suscripción
            </button>
          ) : (
            <button className={`w-full py-2 rounded-lg shadow ${user.activeSus && !user.pendingCancellation ? 'bg-red-500 hover:bg-red-600' : 'bg-green-500 hover:bg-green-600'}`} onClick={user.activeSus && !user.pendingCancellation ? handleCancelSubscription : handleSubscription}>
              {user.activeSus && !user.pendingCancellation ? 'Cancelar' : 'Suscribirse'}
            </button>
          )}
        </div>
        <div className="col-span-full">
          <button className="w-full text-white bg-gray-800 hover:bg-gray-900 py-2 rounded-lg shadow mt-4" onClick={handleLogout}>
            Cerrar Sesión
          </button>
        </div>
      </div>
    </div>
  );  
};

export default User;
