import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useGlobal } from '../context/GlobalContext';
import CheckoutService from '../services/checkout';
import { FaBell, FaTrophy, FaTasks, FaDumbbell, FaCommentDots, FaEuroSign, FaBook } from 'react-icons/fa';
import UserService from '../services/User';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import FacebookEventService from '../services/FbConverted';

const MySwal = withReactContent(Swal);

const PricingTable = ({
  precioRefPro, precioDctoPro, precioRefVip, precioDctoVip,
  priceIdBasic, priceIdPro, priceIdVip,
  cuponIdBasicToPro, cuponIdBasicToVip, cuponIdProToVip,
  cuponVipToPro, cuponVipToBasic, cuponProToBasic
}) => {
  const { state } = useGlobal();
  const { user } = state;

  useEffect(() => {
    if (
      cuponIdBasicToPro || cuponIdBasicToVip || cuponIdProToVip ||
      cuponVipToPro || cuponVipToBasic || cuponProToBasic
    ) {
      MySwal.fire({
        title: 'Información',
        text: 'Cambia de plan cada vez que lo necesites, si quieres mejorar tu suscripción, solo abonas la diferencia entre el precio de las mensualidades',
        icon: 'info',
        confirmButtonText: 'Entendido'
      });
    }
  }, [
    cuponIdBasicToPro, cuponIdBasicToVip, cuponIdProToVip,
    cuponVipToPro, cuponVipToBasic, cuponProToBasic
  ]);

  const handleSubscription = async (priceId, subscriptionCategory) => {
    try {
      if (!user) {
        alert("Usuario no disponible");
        return;
      }

      if (user.subscriptionCategory === subscriptionCategory) {
        MySwal.fire({
          title: 'Suscripción activa',
          text: 'Ya tienes una suscripción activa en este plan',
          icon: 'warning',
          confirmButtonText: 'Entendido'
        });
        return;
      }

      let couponId = null;

      // Verificar si los cupones están definidos antes de proceder
      if (
        cuponIdBasicToPro || cuponIdBasicToVip || cuponIdProToVip ||
        cuponVipToPro || cuponVipToBasic || cuponProToBasic
      ) {
        // Determinar el cupón según la suscripción actual del usuario y la nueva suscripción
        switch (user.subscriptionCategory) {
          case 'basic':
            if (subscriptionCategory === 'pro' && cuponIdBasicToPro) {
              couponId = cuponIdBasicToPro;
            } else if (subscriptionCategory === 'vip' && cuponIdBasicToVip) {
              couponId = cuponIdBasicToVip;
            }
            break;
          case 'pro':
            if (subscriptionCategory === 'vip' && cuponIdProToVip) {
              couponId = cuponIdProToVip;
            } else if (subscriptionCategory === 'basic' && cuponProToBasic) {
              couponId = cuponProToBasic;
            }
            break;
          case 'vip':
            if (subscriptionCategory === 'pro' && cuponVipToPro) {
              couponId = cuponVipToPro;
            } else if (subscriptionCategory === 'basic' && cuponVipToBasic) {
              couponId = cuponVipToBasic;
            }
            break;
          default:
            break;
        }
      }

      console.log("Attempting subscription with priceId:", priceId, "and couponId:", couponId);
      const session = await CheckoutService.createCheckoutSessionSubscription(user.id, priceId, couponId, subscriptionCategory);
      console.log("Session response:", session);
      window.location.href = session.session_url;
    } catch (error) {
      console.log("Error al suscribirse:", error);
      alert(error.message);
    }
  };

  return (
    <div className="flex flex-col max-w-2xl mx-auto my-5 border border-gray-300 rounded-lg shadow-lg" style={{ marginTop: "10px" }}>
      <div className="bg-white rounded-lg shadow-lg p-6 sm:p-4 max-w-4xl w-full">
        <h2 className="text-2xl sm:text-3xl font-bold text-center text-gray-800 mb-4 sm:mb-8">Comparación de Planes</h2>
        <table className="min-w-full bg-white border-gray-300">
          <thead>
            <tr>
              <th className="w-1/7 py-1 sm:py-2 px-1 sm:px-2 bg-gray-800 text-white text-center"></th>
              <th className="w-1/5 py-1 sm:py-2 px-1 sm:px-2 bg-gray-800 text-white text-center">Basic</th>
              <th className="w-1/5 py-1 sm:py-2 px-1 sm:px-2 bg-gray-800 text-white text-center">Pro</th>
              <th className="w-1/5 py-1 sm:py-2 px-1 sm:px-2 bg-gray-800 text-white text-center">VIP</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"><FaBook/></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
            </tr>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"><FaTasks /></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
            </tr>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"><FaDumbbell /></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">15</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">30</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
            </tr>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"><FaCommentDots /></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">30</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">60</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">Ilimitado</td>
            </tr>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"><FaBell /></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">✔</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">✔</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">✔</td>
            </tr>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"><FaTrophy /></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">✔</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">✔</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">✔</td>
            </tr>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"><FaEuroSign /></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center text-sm">9.99€/mes</td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center text-sm">
                <div className="flex flex-col items-center">
                  <span className="line-through mr-1 sm:mr-2">{precioRefPro}€</span>
                  <span className="font-bold text-green-600">{precioDctoPro}€/mes</span>
                </div>
              </td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center text-sm">
                <div className="flex flex-col items-center">
                  <span className="line-through mr-1 sm:mr-2">{precioRefVip}€</span>
                  <span className="font-bold text-green-600">{precioDctoVip}€/mes</span>
                </div>
              </td>
            </tr>
            <tr>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center"></td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg text-xs md:text-sm lg:text-base"
                  onClick={() => handleSubscription(priceIdBasic, 'basic')}
                >
                  Suscribirme
                </button>
              </td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg text-xs md:text-sm lg:text-base"
                  onClick={() => handleSubscription(priceIdPro, 'pro')}
                >
                  Suscribirme
                </button>
              </td>
              <td className="border-t border-gray-300 py-1 sm:py-2 px-1 sm:px-2 text-center">
                <button
                  className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-2 rounded-lg text-xs md:text-sm lg:text-base"
                  onClick={() => handleSubscription(priceIdVip, 'vip')}
                >
                  Suscribirme
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CheckoutPage = () => {
  const location = useLocation();

  const { 
    precioRefPro, precioDctoPro, precioRefVip, precioDctoVip, 
    priceIdBasic, priceIdPro, priceIdVip,
    cuponIdBasicToPro, cuponIdBasicToVip, cuponIdProToVip,
    cuponVipToPro, cuponVipToBasic, cuponProToBasic 
  } = location.state || {};

  return (
    <PricingTable
      precioRefPro={precioRefPro} 
      precioDctoPro={precioDctoPro} 
      precioRefVip={precioRefVip}
      precioDctoVip={precioDctoVip}
      priceIdBasic={priceIdBasic}
      priceIdPro={priceIdPro}
      priceIdVip={priceIdVip}
      cuponIdBasicToPro={cuponIdBasicToPro}
      cuponIdBasicToVip={cuponIdBasicToVip}
      cuponIdProToVip={cuponIdProToVip}
      cuponVipToPro={cuponVipToPro}
      cuponVipToBasic={cuponVipToBasic}
      cuponProToBasic={cuponProToBasic}
    />
  );
};

export default CheckoutPage;
