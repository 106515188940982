import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/notepads'; // Reemplaza con la URL de tu backend


const notePadService = {
  createNotePad: async (notePadData) => {
    try {
      const response = await axios.post(baseUrl, notePadData);
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'Error al crear la nota';
    }
  },

  getAllNotePads: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'Error al obtener todas las notas';
    }
  },

  getNotePadById: async (notePadId) => {
    try {
      const response = await axios.get(`${baseUrl}/${notePadId}`);
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'Error al obtener la nota por ID';
    }
  },

  updateNotePad: async (notePadId, updatedData) => {
    try {
      const response = await axios.put(`${baseUrl}/${notePadId}`, updatedData);
      return response.data;
    } catch (error) {
      throw error.response?.data?.message || 'Error al actualizar la nota';
    }
  },

  deleteNotePad: async (notePadId) => {
    try {
      await axios.delete(`${baseUrl}/${notePadId}`);
      // Puede ser necesario retornar algún dato en caso de éxito
    } catch (error) {
      throw error.response?.data?.message || 'Error al eliminar la nota';
    }
  },
};

export default notePadService;
