import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/categoryTests'; // Reemplaza con la URL de tu backend
// const baseUrl = 'http://localhost:3001/api/categoryTests'; // Reemplaza con la URL de tu backend

const CategoryTestService = {
  // Crear una nueva categoría de prueba
  createCategoryTest: async (name, cuerpo, year) => {
    try {
      const response = await axios.post(baseUrl, {
        name,
        cuerpo,
        year
      });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al crear categoría de prueba';
    }
  },

  // Obtener todas las categorías de prueba
  getAllCategoryTests: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener categorías de prueba';
    }
  },

  // Obtener una categoría de prueba por ID
  getCategoryTestById: async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al obtener categoría de prueba';
    }
  },

  // Actualizar una categoría de prueba por ID
  updateCategoryTest: async (id, name, tests, cuerpo) => {
    try {
      const response = await axios.put(`${baseUrl}/${id}`, {
        name,
        tests,
        cuerpo
      });
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al actualizar categoría de prueba';
    }
  },

  // Eliminar una categoría de prueba por ID
  deleteCategoryTest: async (id) => {
    try {
      const response = await axios.delete(`${baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      throw error.response.data.message || 'Error al eliminar categoría de prueba';
    }
  }
};

export default CategoryTestService;
