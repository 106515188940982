import React, { useState, useEffect } from 'react';
import { useGlobal } from '../context/GlobalContext';
import ProgressBar from './ProgressBar';
import testService from '../services/TestService';
import retoService from '../services/Retos';
import UserService from '../services/User';
import ConversationService from '../services/Conversation';
import WeeklyChallengeRanking from './WeeklyChallengeRanking';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';
import Countdown from 'react-countdown';

const MySwal = withReactContent(Swal);

const Retos = () => {
  const [currentReto, setCurrentReto] = useState(null);
  const { state, dispatch } = useGlobal();
  const { user } = state;
  const [testsAprobados, setTestsAprobados] = useState(0);

  const getCurrentWeekIndex = () => {
    const startDate = new Date(2023, 0, 1);
    const now = new Date();
    const diff = Math.floor((now - startDate) / (7 * 24 * 60 * 60 * 1000));
    return diff % 52;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        MySwal.fire({
          title: 'Cargando retos...',
          html: (
            <div>
              <Circles color="#9CAFB7" height={80} width={80} />
            </div>
          ),
          allowOutsideClick: false,
          allowEscapeKey: false,
          showConfirmButton: false,
        });

        const retosActivos = await retoService.getAllRetos();
        const testsWithScores = await Promise.all(user.testCompleted.map(async (test) => {
          const testDetails = await testService.getTestById(test.testId);
          return { ...test, numberPreguntas: testDetails.numberPreguntas };
        }));

        const approvedTests = testsWithScores.filter(test => test.score >= test.numberPreguntas / 2);
        setTestsAprobados(approvedTests.length);

        const updatedRetosCompletados = {};
        retosActivos.forEach(reto => {
          const totalScore = approvedTests.length + user.practicosCompleted.length;
          if (totalScore >= reto.score) {
            updatedRetosCompletados[reto._id] = true;
          }
        });
        dispatch({ type: 'UPDATE_RETOS_COMPLETADOS', payload: updatedRetosCompletados });

        const currentIndex = getCurrentWeekIndex();
        setCurrentReto(retosActivos[currentIndex % retosActivos.length]);

      } catch (error) {
        console.error('Error:', error);
        MySwal.fire('Error', 'Hubo un error al cargar los retos. Por favor, intenta de nuevo.', 'error');
      } finally {
        setTimeout(() => {
          MySwal.close();
        }, 1500)
      }
    };

    if (user && !currentReto) {
      fetchData();
    }
  }, [user, dispatch, currentReto]);

  const handleCanje = async (retoId) => {
    const reto = currentReto;
    if (!reto) return;

    try {
      if (reto.tipoRecompensa === 'creditosCorreccion') {
        await UserService.addCreditosCorreccion(user.id, reto.valorRecompensa);
      } else if (reto.tipoRecompensa === 'creditosConsulta') {
        await ConversationService.addCreditosConsulta(user.id, reto.valorRecompensa, user.subscriptionCategory);
      }

      await UserService.canjeReto(user.id, reto._id);
      dispatch({ type: 'UPDATE_RETOS_CANJEADOS', payload: [...user.retosCanjeados, retoId] });
      Swal.fire('Recompensa canjeada exitosamente', '', 'success');
    } catch (error) {
      console.error('Error al canjear recompensa:', error);
      Swal.fire('No se pudo canjear la recompensa.', '', 'error');
    }
  };

  const calculateProgress = (current, total) => {
    return Math.min(current, total);
  };

  const renderer = ({ days, hours, minutes, seconds }) => (
    <div className="bg-red-600 text-white p-2 rounded mb-4">
      <p className="text-center text-sm">Tiempo restante: {days}d : {hours}h : {minutes}m : {seconds}s</p>
    </div>
  );

  return (
    <div>
      <h2 className="text-2xl font-bold text-gray-800 mb-6 text-center">Reto Semanal</h2>
      {currentReto && (
        <div className={`rounded-lg shadow-lg p-4 ${user.retosCanjeados.includes(currentReto._id) ? 'bg-green-100' : 'bg-white'}`}>
          <h3 className="text-lg font-semibold text-gray-800 mb-4">Reto: {currentReto.nombre}</h3>
          <Countdown date={Date.now() + (7 * 24 * 60 * 60 * 1000)} renderer={renderer} />
          <div className="mb-2">
            <p className="text-gray-600 text-sm mb-1">Test Aprobados: {testsAprobados}/{currentReto.testAprobados}</p>
            <ProgressBar progress={calculateProgress(testsAprobados, currentReto.testAprobados)} total={currentReto.testAprobados} />
          </div>
          <div className="mb-2">
            <p className="text-gray-600 text-sm mb-1">Prácticos Realizados: {user.practicosCompleted.length}/{currentReto.simulacros}</p>
            <ProgressBar progress={calculateProgress(user.practicosCompleted.length, currentReto.simulacros)} total={currentReto.simulacros} />
          </div>
          <div className="mb-2">
            <p className="text-gray-600 text-sm mb-1">Score: {testsAprobados + user.practicosCompleted.length}/{currentReto.score}</p>
            <ProgressBar progress={calculateProgress(testsAprobados + user.practicosCompleted.length, currentReto.score)} total={currentReto.score} />
          </div>
          <p className="text-gray-600 text-sm mb-1">Recompensa: {currentReto.recompensa}</p>
          {user.retosCompletados.includes(currentReto._id) && !user.retosCanjeados.includes(currentReto._id) && (
            <button
              className="mt-4 bg-[#9CAFB7] text-black rounded p-2 w-full"
              onClick={() => handleCanje(currentReto._id)}
            >
              Canjear recompensa
            </button>
          )}
          {user.retosCanjeados.includes(currentReto._id) && (
            <p className="text-black-600 text-sm mb-1"><strong>Ya has canjeado este reto</strong></p>
          )}
        </div>
      )}
    </div>
  );
};

export default Retos;
