// Stats.js
import React from 'react';

const Stats = ({ user, testsAprobados }) => {
  return (
    <>
      <aside className="bg-white p-4 rounded-lg shadow mb-8 w-full">
        <div className="text-center py-2 border-b border-gray-200 mb-4">
          <span className="text-lg font-semibold text-gray-700">Puntuación Total:</span>
          <span className="text-xl font-bold ml-2" style={{ color: '#9CAFB7' }}>
            {user.practicosCompleted.length + testsAprobados}
          </span>
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-4 gap-4 text-center">
          <div className="py-2">
            <span className="text-xl font-bold text-gray-600">{user.testCompleted.length}</span>
            <span className="block text-sm text-gray-600">Tests Completados</span>
          </div> 
          <div className="py-2">
            <span className="text-xl font-bold text-gray-600">{testsAprobados}</span>
            <span className="block text-sm text-gray-600">Tests Aprobados</span>
          </div>
          <div className="py-2">
            <span className="text-xl font-bold text-gray-600">{user.practicosCompleted.length}</span>
            <span className="block text-sm text-gray-600">Prácticos Completados</span>
          </div>
          <div className="py-2">
            <span className="text-xl font-bold text-gray-600">{user.retosCanjeados.length}</span>
            <span className="block text-sm text-gray-600">Retos Completados</span>
          </div>
        </div>
      </aside>
    </>
  );
};

export default Stats;
