import React, { useEffect, useState } from 'react';
import { FaPlus, FaTrash, FaHeart, FaRegHeart, FaShareAlt } from 'react-icons/fa';
import { useGlobal } from '../context/GlobalContext';
import { useNavigate, useLocation } from 'react-router-dom';
import noticiaService from '../services/Noticia';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Circles } from 'react-loader-spinner';

const MySwal = withReactContent(Swal);

const NewsLetter = () => {
  const [searchText, setSearchText] = useState('');
  const [notices, setNotices] = useState([]);
  const { state } = useGlobal();
  const { user } = state;
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const fetchNotices = async () => {
      MySwal.fire({
        title: 'Cargando noticias...',
        html: (
          <div className="flex flex-col items-center justify-center">
            <Circles color="#9CAFB7" height={80} width={80} />
          </div>
        ),
        didOpen: async () => {
          try {
            const notices = await noticiaService.getAllNoticias();
            setNotices(notices);

            const anchorId = location.hash.replace('#', '');
            if (anchorId) {
              const element = document.getElementById(anchorId);
              if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
              }
            }

            setTimeout(() => {
              MySwal.close();
            }); // Esperar 3 segundos antes de cerrar la animación
          } catch (error) {
            console.error('Error fetching notices:', error);
            MySwal.fire('Error', 'Hubo un error al cargar las noticias. Por favor, intenta de nuevo.', 'error');
          }
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        showConfirmButton: false,
      });
    };

    fetchNotices();
  }, [location]);

  useEffect(() => {
    const anchorId = location.hash.replace('#', '');
    if (anchorId) {
      const element = document.getElementById(anchorId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location, notices]);

  const handleAddNoticia = () => {
    navigate('/createNoticia');
  };

  const confirmDelete = async (noticiaId, event) => {
    event.stopPropagation();
    const result = await MySwal.fire({
      title: '¿Estás seguro?',
      text: 'No podrás revertir esto',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Sí, eliminar',
      cancelButtonText: 'Cancelar',
    });

    if (result.isConfirmed) {
      handleDelete(noticiaId);
    }
  };

  const handleDelete = async (noticiaId) => {
    try {
      await noticiaService.deleteNoticia(noticiaId);
      setNotices(notices.filter((noticia) => noticia.id !== noticiaId));
    } catch (error) {
      console.error('Error deleting noticia:', error);
    }
  };

  const handleLike = async (noticiaId, likedBy, event) => {
    event.stopPropagation();
    try {
      if (likedBy.includes(user.id)) {
        return; // El usuario ya ha dado like a esta noticia
      }

      await noticiaService.likeNoticia(noticiaId, user.id);
      setNotices((prevNotices) =>
        prevNotices.map((noticia) =>
          noticia.id === noticiaId ? { ...noticia, likedBy: [...noticia.likedBy, user.id], like: noticia.like + 1 } : noticia
        )
      );
    } catch (error) {
      console.error('Error liking noticia:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  const filteredNoticias = notices.filter((noticia) =>
    noticia.text.toLowerCase().includes(searchText.toLowerCase()) ||
    noticia.comunidad.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleShare = (sharedUrl, event) => {
    event.stopPropagation();
    navigator.clipboard.writeText(sharedUrl).then(() => {
      Swal.fire('¡Enlace copiado!', 'El enlace de la noticia ha sido copiado al portapapeles.', 'success');
    }).catch((error) => {
      console.error('Error copying link:', error);
      Swal.fire('Error', 'Hubo un error al copiar el enlace. Por favor, intenta de nuevo.', 'error');
    });
  };

  const handleNoticiaClick = (url) => {
    window.open(url, '_blank');
  }

  return (
    <div className="flex flex-col items-center p-6 w-full max-w-full sm:max-w-3xl mx-auto bg-gray-100">
      <h2 className="mb-6 text-2xl font-bold text-gray-800">Noticias</h2>
      <input
        className="w-full max-w-md sm:max-w-full h-10 mb-6 border border-gray-300 rounded-md px-4"
        type="text"
        placeholder="Buscar..."
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
      />
      <ul className="w-full flex flex-col items-center">
        {filteredNoticias.map((noticia) => (
          <li
            onClick={() => handleNoticiaClick(noticia.url)} // Suponiendo que tienes una propiedad url en la noticia
            key={noticia.id}
            id={noticia.id}
            className="w-full max-w-xl bg-white shadow-lg rounded-lg overflow-hidden p-4 mb-6 transition duration-300 ease-in-out hover:bg-gray-50 cursor-pointer"
          >
            <p className="text-lg font-semibold text-gray-800">{noticia.title}</p>
            <p className="text-gray-600">{noticia.text}</p>
            <p className="text-gray-500">Fecha: {formatDate(noticia.date)}</p>
            <div className="flex items-center justify-between mt-2 flex-wrap">
              <p className="text-gray-500">Creado por: {user.userNameUnique}</p>
              <div className="flex items-center space-x-8 md:space-x-12">
                <button
                  onClick={(e) => handleLike(noticia.id, noticia.likedBy, e)}
                  className={`flex items-center ${noticia.likedBy.includes(user.id) ? 'text-red-700' : 'text-red-500 hover:text-red-700'}`}
                >
                  {noticia.likedBy.includes(user.id) ? <FaHeart /> : <FaRegHeart />}
                  <span className="ml-3">{noticia.like}</span>
                </button>
                <button
                  onClick={(e) => handleShare(`${window.location.origin}/noticias#${noticia.id}`, e)}
                  className="flex items-center text-blue-500 hover:text-blue-700" style={{marginLeft: "45px"}}
                >
                  <FaShareAlt />
                </button>
                {user.id === noticia.user && (
                  <button onClick={(e) => confirmDelete(noticia.id, e)} className="text-red-500 hover:text-red-700" style={{marginLeft: "60px"}}>
                    <FaTrash />
                  </button>
                )}
              </div>
            </div>
          </li>
        ))}
      </ul>
      <button
        className="fixed bottom-20 right-8 bg-[#9CAFB7] text-black p-4 rounded-full shadow-lg hover:bg-[#8a9ea7] transition duration-300 ease-in-out"
        onClick={handleAddNoticia}
      >
        <FaPlus className="text-2xl" />
      </button>
    </div>
  );
};

export default NewsLetter;



