import axios from 'axios';

const baseUrl = 'https://opocheck.onrender.com/api/cuerpos'; // Cambia esto por la URL correcta de tu backend

const CuerpoService = {
  createCuerpo: async (cuerpoData) => {
    try {
      const response = await axios.post(baseUrl, cuerpoData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al crear cuerpo');
    }
  },

  getAllCuerpos: async () => {
    try {
      const response = await axios.get(baseUrl);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener cuerpos');
    }
  },

  getCuerpoById: async (id) => {
    try {
      const response = await axios.get(`${baseUrl}/${id}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener cuerpo por ID');
    }
  },

  getCuerpoByName: async (name) => {
    try {
      const response = await axios.get(`${baseUrl}/byname?name=${name}`);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al obtener cuerpo por nombre');
    }
  },

  updateCuerpo: async (id, cuerpoData) => {
    try {
      const response = await axios.put(`${baseUrl}/${id}`, cuerpoData);
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al actualizar cuerpo');
    }
  },

  deleteCuerpo: async (id) => {
    try {
      await axios.delete(`${baseUrl}/${id}`);
      return true;
    } catch (error) {
      throw new Error(error.response?.data?.message || 'Error al eliminar cuerpo');
    }
  },
};

export default CuerpoService;
