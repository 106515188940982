import React, { useState, useEffect } from 'react';
import { FaRegClock } from 'react-icons/fa';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PracticoService from '../services/practicos';
import { Circles } from 'react-loader-spinner';
import FullScreenModal from './FullScreenModal';
import { useGlobal } from '../context/GlobalContext';

const MySwal = withReactContent(Swal);

const formatContent = (text) => {
  if (!text) return null;

  const lines = text.split('\n');
  const formattedLines = lines.map((line) => {
    if (line.startsWith('- ')) {
      return `<li>${line.slice(2)}</li>`;
    } else if (/^\d+\.\s/.test(line)) {
      return `<p class="ml-4">${line}</p>`;
    } else if (line.trim() === '') {
      return `<br />`;
    } else {
      return `<p>${line}</p>`;
    }
  });

  return formattedLines.join('');
};

const PracticosInterface = ({ desarrollo, multiple, duracion, onSubmit, mode = 'test', respuestasUsuario = [], enunciado, id }) => {
  const [respuestas, setRespuestas] = useState([]);
  const [tiempoRestante, setTiempoRestante] = useState(duracion);
  const [submitted, setSubmitted] = useState(false);
  const [correcciones, setCorrecciones] = useState({});
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const { state } = useGlobal();
  const { user, cuerpo } = state;

  useEffect(() => {
    if (mode === 'test') {
      const timerId = tiempoRestante > 0 && setInterval(() => {
        setTiempoRestante(prevTime => prevTime - 1);
      }, 1000);
      return () => clearInterval(timerId);
    }
  }, [tiempoRestante, mode]);

  const handleOptionChange = (index, opcion) => {
    const nuevasRespuestas = [...respuestas];
    nuevasRespuestas[index] = opcion;
    setRespuestas(nuevasRespuestas);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitted(true);

    MySwal.fire({
      title: 'Corrigiendo...',
      html: (
        <div className="flex flex-col items-center justify-center">
          <Circles color="#1a73e8" height={80} width={80} />
        </div>
      ),
      allowOutsideClick: false,
      allowEscapeKey: false,
      showConfirmButton: false,
    });

    const correccionesDesarrollo = await Promise.all(
      desarrollo.map(async (pregunta, index) => {
        const respuesta = respuestas[index];
        try {
          if (!user.id || !id || !pregunta || !respuesta || !enunciado || !cuerpo.correctorSimulacro || !cuerpo.largeName) {
            console.log("Faltan datos necesarios para corregir la respuesta.");
            return 'Datos incompletos para la corrección';
          }

          const response = await PracticoService.corregirPractico(
            user.id,
            id,
            pregunta,
            respuesta,
            enunciado,
            cuerpo.correctorSimulacro,
            cuerpo.largeName
          );

          console.log("Corrección recibida:", response);

          return response.correccion || 'Sin corrección disponible';
        } catch (error) {
          console.error('Error al corregir la respuesta:', error);
          return 'Error al corregir la respuesta';
        }
      })
    );

    const nuevasCorrecciones = correccionesDesarrollo.reduce((acc, correccion, index) => {
      acc[index] = correccion;
      return acc;
    }, {});

    setCorrecciones(nuevasCorrecciones);
    MySwal.close();
    onSubmit(respuestas, nuevasCorrecciones);
  };

  const formatTime = () => {
    const minutos = Math.floor(tiempoRestante / 60);
    const segundos = tiempoRestante % 60;
    return `${minutos}:${segundos < 10 ? '0' : ''}${segundos}`;
  };

  const formattedEnunciado = formatContent(enunciado);

  return (
    <>
      {mode === 'test' && (
        <div className="fixed top-4 left-1/2 transform -translate-x-1/2 z-50 bg-white shadow-md rounded-md p-1 flex items-center justify-center gap-2" style={{ marginTop: "50px" }}>
          <FaRegClock />
          <span>{formatTime()}</span>
        </div>
      )}
      <div className="pb-8 w-full min-h-screen">
        <form onSubmit={handleSubmit} className="space-y-6 w-full">
          {desarrollo.map((pregunta, index) => (
            <div key={index} className="w-full p-4">
              <p className="text-sg text-gray-700 mb-4" dangerouslySetInnerHTML={{ __html: formatContent(`${index + 1}. ${pregunta}`) }}></p>
              <textarea
                rows="4"
                className="w-full p-4 border border-gray-300 rounded-lg text-base focus:outline-none focus:ring-2 focus:ring-indigo-600 transition duration-200"
                value={respuestas[index] || ''}
                onChange={(e) => handleOptionChange(index, e.target.value)}
                disabled={mode !== 'test' || submitted || tiempoRestante <= 0}
              />
              {mode === 'review' && correcciones[index] && (
                <div className="mt-4 p-4 bg-gray-50 border-l-4 border-gray-500 rounded-lg">
                  <h4 className="text-gray-800">Corrección:</h4>
                  <div className="text-gray-700 text-sm" dangerouslySetInnerHTML={{ __html: correcciones[index] }}></div>
                </div>
              )}
            </div>
          ))}
          {multiple.map((pregunta, index) => (
            <div key={index} className="w-full p-4">
              <p className="text-gray-700 mb-4 text-base" dangerouslySetInnerHTML={{ __html: formatContent(`${index + 1 + desarrollo.length}. ${pregunta.pregunta}`) }}></p>
              {pregunta.opciones.map((opcion, i) => (
                <label key={i} className={`block p-3 mb-2 cursor-pointer border ${mode === 'review' ? respuestasUsuario[index + desarrollo.length] === opcion ? opcion !== pregunta.opcionCorrecta ? 'bg-red-100 border-red-300' : 'bg-green-100 border-green-300' : opcion === pregunta.opcionCorrecta ? 'bg-green-100 border-green-300' : 'border-gray-300' : 'border-gray-300'} rounded-lg transition duration-200`}>
                  <input
                    type="radio"
                    name={`pregunta_${index + desarrollo.length}`}
                    value={opcion}
                    checked={respuestas[index + desarrollo.length] === opcion}
                    onChange={() => handleOptionChange(index + desarrollo.length, opcion)}
                    disabled={mode !== 'test' || submitted || tiempoRestante <= 0}
                    className="mr-2 cursor-pointer"
                  />
                  {opcion}
                </label>
              ))}
              {mode === 'review' && (
                <div className="mt-4 italic text-gray-600 text-base">
                  <p>Justificación de la respuesta: {pregunta.justify}</p>
                </div>
              )}
            </div>
          ))}
          {mode === 'test' && (
            <button
              type="submit"
              className="w-full text-white bg-gray-800 hover:bg-gray-900 py-2 rounded-lg shadow mt-4" 
            >
              Enviar Respuestas
            </button>
          )}
        </form>
      </div>
      {mode === 'test' && tiempoRestante <= 0 && !submitted && (
        <div className="fixed bottom-4 left-1/2 transform -translate-x-1/2 bg-red-600 text-white py-2 px-4 rounded-full shadow-lg">
          El tiempo ha terminado.
        </div>
      )}
      <button
        onClick={() => setModalIsOpen(!modalIsOpen)}
        className="fixed bottom-20 right-4 bg-blue-500 text-white p-2 rounded-full shadow-lg z-50"
      >
        {modalIsOpen ? 'Cerrar' : 'Ver Enunciado'}
      </button>
      <FullScreenModal 
        isOpen={modalIsOpen} 
        onRequestClose={() => setModalIsOpen(false)} 
        content={formattedEnunciado}
      />
    </>
  );
};

export default PracticosInterface;
